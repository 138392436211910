import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const ProcessingSectionTitle = ({ title }) => (
  <React.Fragment>
    <Divider />
    <Box width='100%' display='flex' justifyContent='center'>
      <Typography variant='subtitle1'>{title}</Typography>
    </Box>
  </React.Fragment>
);

export default ProcessingSectionTitle;
