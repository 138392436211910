import React from 'react';
import Plot from '../../../components/plot/plot';
import buildLayout from '../../../components/plot/plot-layout';
import buildScatterPlotDataItem from '../../../components/plot/plot-data-item';
import Buttons from '../../../components/plot/buttons';
import buildInsetLayout from '../../../components/plot/inset-plot-layout';
import buildInsetScatterPlotDataItem from '../../../components/plot/inset-plot-data-item';
import { Fade, Popover } from '@mui/material';
import Icons from '../../../components/plot/icons';
import ProcessedCurvesPlotSettings from './processed-curves-plot-settings';
import ProcessedCurvesPlotSettingsData from './processed-curves-plot-settings-models';
import ProcessedCurvesPlotData, {
  HcrLinearPlotData,
  ProcessedBackfieldDemagnetizationPlotData,
  ProcessedHysteresisPlotData,
  ProcessedIrmPlotData,
  SlopeTangentPlotData,
} from './processed-curves-plot-models';
import { useHysteresis } from '../../../context/hysteresis-provider';
import { useIrm } from '../../../context/irm-provider';
import { useBackfield } from '../../../context/backfield-provider';

const Colors = {
  HYSTERESIS: '#3b75af',
  IRM: '#f19531',
  BACKFIELD: '#ff0000',
  SLOPE_TANGENT: '#29034d',
  HCR: '#35cb07',
};

/**
 * @param {ProcessedHysteresisPlotData} hysteresis
 * @param {ProcessedIrmPlotData} irm
 * @param {ProcessedBackfieldDemagnetizationPlotData} backfieldDemagnetization
 * @return {{maxMoment: number, maxField: number}}
 */
const extractMaxAxisValues = (hysteresis, irm, backfieldDemagnetization) => {
  let maxField = 0, maxMoment = 0;
  if (!hysteresis.isEmpty()) {
    maxField = Math.max(
      Math.abs(hysteresis.topCurveFields[0]),
      Math.abs(hysteresis.topCurveFields[hysteresis.topCurveFields.length - 1]),
      Math.abs(hysteresis.bottomCurveFields[0]),
      Math.abs(hysteresis.bottomCurveFields[hysteresis.bottomCurveFields.length - 1]),
    );
    maxMoment = Math.max(
      Math.abs(hysteresis.topCurveMoments[0]),
      Math.abs(hysteresis.topCurveMoments[hysteresis.topCurveMoments.length - 1]),
      Math.abs(hysteresis.bottomCurveMoments[0]),
      Math.abs(hysteresis.bottomCurveMoments[hysteresis.bottomCurveMoments.length - 1]),
    );
  }
  if (!irm.isEmpty()) {
    maxField = Math.max(
      maxField,
      irm.fields[irm.fields.length - 1],
    );
    maxMoment = Math.max(
      maxMoment,
      irm.moments[irm.moments.length - 1],
    );
  }
  if (!backfieldDemagnetization.isEmpty()) {
    maxField = Math.max(
      maxField,
      Math.abs(backfieldDemagnetization.fields[backfieldDemagnetization.fields.length - 1]),
    );
    maxMoment = Math.max(
      maxMoment,
      Math.abs(backfieldDemagnetization.moments[backfieldDemagnetization.moments.length - 1]),
    );
  }

  return {
    maxField,
    maxMoment,
  };
};

/**
 * @param {PlotSettings} settings
 * @param {ProcessedCurvesPlotData} data
 * @return {JSX.Element}
 * @constructor
 */
const ProcessedCurvesPlot = ({ settings }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [extraSettings, setExtraSettings] = React.useState(ProcessedCurvesPlotSettingsData.default());

  const { processedLoop } = useHysteresis();
  const { processedIrm } = useIrm();
  const { processedBackfield } = useBackfield();

  const data = new ProcessedCurvesPlotData({
    hysteresis: processedLoop ? ProcessedHysteresisPlotData.fromProcessedLoop(processedLoop) : ProcessedHysteresisPlotData.empty(),
    irm: processedIrm ? ProcessedIrmPlotData.fromProcessedCurve(processedIrm) : ProcessedIrmPlotData.empty(),
    backfieldDemagnetization: processedBackfield ? ProcessedBackfieldDemagnetizationPlotData.fromProcessedCurve(processedBackfield) : ProcessedBackfieldDemagnetizationPlotData.empty(),
    slopeTangent: processedLoop ? SlopeTangentPlotData.fromProcessedLoop(processedLoop) : SlopeTangentPlotData.empty(),
    hcrLinear: processedBackfield ? HcrLinearPlotData.fromProcessedCurve(processedBackfield) : HcrLinearPlotData.empty(),
    fieldUnits: processedLoop ? processedLoop.field_units : processedIrm ? processedIrm.field_units : processedBackfield ? processedBackfield.field_units : '',
    momentUnits: processedLoop ? processedLoop.moment_units : processedIrm ? processedIrm.moment_units : processedBackfield ? processedBackfield.moment_units : '',
  });
  let { maxField, maxMoment } = extractMaxAxisValues(data.hysteresis, data.irm, data.backfieldDemagnetization);

  const insetPlotData = [];
  let insetPlotLayout = {};
  if (extraSettings.insetPlot.show) {
    insetPlotData.push(
      buildInsetScatterPlotDataItem({
        name: extraSettings.hysteresisDisplayName,
        mode: settings.plotlyMode(),
        color: Colors.HYSTERESIS,
        xs: data.hysteresis.topCurveFields.concat([...data.hysteresis.bottomCurveFields]),
        ys: data.hysteresis.topCurveMoments.concat([...data.hysteresis.bottomCurveMoments]),
      }),
      buildInsetScatterPlotDataItem({
        name: extraSettings.irmDisplayName,
        mode: settings.plotlyMode(),
        color: Colors.IRM,
        xs: data.irm.fields,
        ys: data.irm.moments,
      }),
      buildInsetScatterPlotDataItem({
        name: extraSettings.backfieldDisplayName,
        mode: settings.plotlyMode(),
        color: Colors.BACKFIELD,
        xs: data.backfieldDemagnetization.fields,
        ys: data.backfieldDemagnetization.moments,
      }),
    );

    const insetPlotMaxField = maxField * extraSettings.insetPlot.fieldRatio;
    const insetPlotMaxMoment = maxMoment * extraSettings.insetPlot.momentRatio;
    insetPlotLayout = buildInsetLayout(
      [-insetPlotMaxField, insetPlotMaxField],
      [-insetPlotMaxMoment, insetPlotMaxMoment],
    );
  }

  const slopeCorrectionData = [];
  if (extraSettings.slopeTangent.show) {
    slopeCorrectionData.push(buildScatterPlotDataItem({
      mode: settings.plotlyMode(),
      name: extraSettings.slopeTangent.displayName,
      color: Colors.SLOPE_TANGENT,
      xs: data.slopeTangent.fields,
      ys: data.slopeTangent.moments,
    }));

    const maxSlopeTangentMoment = Math.max(...data.slopeTangent.moments.map(Math.abs));
    if (isFinite(maxSlopeTangentMoment) && maxSlopeTangentMoment > maxMoment) {
      maxMoment = maxSlopeTangentMoment;
    }
  }

  const hcrCalculationsData = [];
  if (extraSettings.hcrLinear.show) {
    hcrCalculationsData.push(buildScatterPlotDataItem({
      mode: settings.plotlyMode(),
      name: extraSettings.hcrLinear.displayName,
      color: Colors.HCR,
      xs: data.hcrLinear.fields,
      ys: data.hcrLinear.moments,
    }));
  }

  const plotLayout = buildLayout('', data.fieldUnits, data.momentUnits, 1.15 * maxField, 1.15 * maxMoment);
  const plotData = [
    buildScatterPlotDataItem({
      mode: settings.plotlyMode(),
      name: extraSettings.hysteresisDisplayName,
      color: Colors.HYSTERESIS,
      xs: data.hysteresis.topCurveFields.concat([...data.hysteresis.bottomCurveFields]),
      ys: data.hysteresis.topCurveMoments.concat([...data.hysteresis.bottomCurveMoments]),
    }),
    buildScatterPlotDataItem({
      mode: settings.plotlyMode(),
      name: extraSettings.irmDisplayName,
      color: Colors.IRM,
      xs: data.irm.fields,
      ys: data.irm.moments,
    }),
    buildScatterPlotDataItem({
      mode: settings.plotlyMode(),
      name: extraSettings.backfieldDisplayName,
      color: Colors.BACKFIELD,
      xs: data.backfieldDemagnetization.fields,
      ys: data.backfieldDemagnetization.moments,
    }),
    ...slopeCorrectionData,
    ...hcrCalculationsData,
    ...insetPlotData,
  ];
  const layout = {
    ...plotLayout,
    ...insetPlotLayout,
    modebar: {
      orientation: 'v',
    },
  };

  return (
    <React.Fragment>
      <Plot
        divId='processed-curves-plot'
        data={plotData}
        layout={layout}
        config={
          {
            displaylogo: false,
            modeBarButtons: [
              [
                'zoomIn2d', 'zoomOut2d', 'autoScale2d',
                'zoom2d', 'resetScale2d', 'pan2d',
                Buttons.exportAsSvgButton, Buttons.exportAsPngButton,
                {
                  name: 'Open settings',
                  icon: Icons.settings,
                  click: function(gd) {
                    setAnchorEl(gd);
                  },
                },
              ],
            ],
          }
        }
        style={{ width: '100%', height: '100%', minHeight: '400px' }}
      />
      <Popover
        id={anchorEl !== null ? 'processed-curves-plot-extra-settings' : undefined}
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: anchorEl && `${anchorEl._fullLayout.width}px`,
            height: anchorEl && `${anchorEl._fullLayout.height}px`,
            display: 'grid',
            gridRowGap: '20px',
            padding: '20px',
          },
        }}
        TransitionComponent={Fade}
      >
        <ProcessedCurvesPlotSettings
          settings={extraSettings}
          onUpdate={(data) => {
            setExtraSettings(new ProcessedCurvesPlotSettingsData(data));
            setAnchorEl(null);
          }}
        />
      </Popover>
    </React.Fragment>
  );
};

export default ProcessedCurvesPlot;
