class ProcessedCurvesPlotSettingsData {
  constructor({
                hysteresisDisplayName,
                irmDisplayName,
                backfieldDisplayName,
                showInsetPlot,
                showSlopeTangent,
                slopeTangentDisplayName,
                showHcrLinear,
                hcrLinearDisplayName,
                insetPlotFieldRatio,
                insetPlotMomentRatio,
              }) {
    this.hysteresisDisplayName = hysteresisDisplayName;
    this.irmDisplayName = irmDisplayName;
    this.backfieldDisplayName = backfieldDisplayName;
    this.insetPlot = {
      show: showInsetPlot,
      fieldRatio: insetPlotFieldRatio,
      momentRatio: insetPlotMomentRatio,
    };
    this.slopeTangent = {
      show: showSlopeTangent,
      displayName: slopeTangentDisplayName,
    };
    this.hcrLinear = {
      show: showHcrLinear,
      displayName: hcrLinearDisplayName,
    };
  }

  /**
   * @return {ProcessedCurvesPlotSettingsData}
   */
  static default() {
    return new ProcessedCurvesPlotSettingsData({
      hysteresisDisplayName: 'Hysteresis',
      irmDisplayName: 'IRM',
      backfieldDisplayName: 'Backfield',
      showInsetPlot: false,
      showSlopeTangent: false,
      slopeTangentDisplayName: 'Slope Tangent',
      showHcrLinear: false,
      hcrLinearDisplayName: '$H_{cr}$',
      insetPlotFieldRatio: 0.2,
      insetPlotMomentRatio: 0.2,
    });
  }
}

export default ProcessedCurvesPlotSettingsData;
