import React from 'react';
import processingSpecContextReducer from './processing-spec/reducer';
import InitialProcessingSpecValue from './processing-spec/init';


const ProcessingSpecStateContext = React.createContext(null);
const ProcessingSpecDispatchContext = React.createContext(null);

const useProcessingSpecState = () => React.useContext(ProcessingSpecStateContext);
const useProcessingSpecDispatcher = () => React.useContext(ProcessingSpecDispatchContext);

const ProcessingSpecProvider = ({ children }) => {
  const [processingSpec, dispatchProcessingSpec] = React.useReducer(processingSpecContextReducer, InitialProcessingSpecValue);

  return (
    <ProcessingSpecStateContext.Provider value={processingSpec}>
      <ProcessingSpecDispatchContext.Provider value={dispatchProcessingSpec}>
        {children}
      </ProcessingSpecDispatchContext.Provider>
    </ProcessingSpecStateContext.Provider>
  );
};

export default ProcessingSpecProvider;

export {
  useProcessingSpecState,
  useProcessingSpecDispatcher,
};
