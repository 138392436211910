class ProcessingStatsData {
  /**
   * @param {HysteresisProcessingStats} [hysteresis]
   */
  constructor({
                hysteresis,
              }) {
    this.hysteresis = hysteresis;
  }

  /**
   * @return {Array<{name: string, value: string}>}
   */
  entries() {
    return [
      {
        name: '$Q$',
        value: this.hysteresis === null ? '' : this.hysteresis.loop_quality,
      },
      {
        name: '$H_o$',
        value: this.hysteresis === null ? '' : this.hysteresis.field_offset,
      },
      {
        name: '$M_o$',
        value: this.hysteresis === null ? '' : this.hysteresis.moment_offset,
      },
      {
        name: '$M_{ce}$',
        value: this.hysteresis === null ? '' : this.hysteresis.closure_error,
      },
      {
        name: '$F_{70\\%}$',
        value: this.hysteresis === null ? '' : this.hysteresis.linear_fit_f_70_value
      },
      {
        name: '$p_{70\\%}$',
        value: this.hysteresis === null ? '' : this.hysteresis.linear_fit_p_70_value
      },
      {
        name: '$F_{80\\%}$',
        value: this.hysteresis === null ? '' : this.hysteresis.linear_fit_f_80_value
      },
      {
        name: '$p_{80\\%}$',
        value: this.hysteresis === null ? '' : this.hysteresis.linear_fit_p_80_value
      },
      {
        name: '$F_{90\\%}$',
        value: this.hysteresis === null ? '' : this.hysteresis.linear_fit_f_90_value
      },
      {
        name: '$p_{90\\%}$',
        value: this.hysteresis === null ? '' : this.hysteresis.linear_fit_p_90_value
      },
    ];
  }

  static empty() {
    return new ProcessingStatsData({
      hysteresis: null,
    });
  }
}

export default ProcessingStatsData;
