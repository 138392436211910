import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import InputAdornment from '@mui/material/InputAdornment';
import ProcessingMethodTitle from '../../components/titles/processing-method-title';
import ProcessingSectionTitle from '../../components/titles/processing-section-title';
import ProcessingMethodOutlinedInput from '../../components/inputs/processing-method-outlined-input';
import UnitSystemController from './controllers/unit-system-controller';
import NormalizationController from './controllers/normalization-controller';
import OffsetCorrectionController from './controllers/offset-correction-controller';
import DriftCorrectionController from './controllers/drift-correction-controller';
import SlopeCorrectionController from './controllers/slope-correction-controller';
import NoiseCancellationController from './controllers/noise-cancellation-controller';
import CurveType from '../../services/hysteresis-online/common/curve-type';

const ProcessingMenu = () => {
  const refMassMg = React.useRef();
  const refAreaCm2 = React.useRef();
  const refVolumeCm3 = React.useRef();

  return (
    <React.Fragment>
      <ProcessingSectionTitle title='Specimen' />
      <List>
        <ProcessingMethodTitle primary='Mass' />
        <ListItem sx={{ padding: '0 5px 0 5px' }}>
          <ProcessingMethodOutlinedInput
            inputRef={refMassMg}
            endAdornment={<InputAdornment position='end'>mg</InputAdornment>}
          />
        </ListItem>

        <ProcessingMethodTitle primary='Area' />
        <ListItem sx={{ padding: '0 5px 0 5px' }}>
          <ProcessingMethodOutlinedInput
            inputRef={refAreaCm2}
            endAdornment={<InputAdornment position='end'>cm²</InputAdornment>}
          />
        </ListItem>

        <ProcessingMethodTitle primary='Volume' />
        <ListItem sx={{ padding: '0 5px 0 5px' }}>
          <ProcessingMethodOutlinedInput
            inputRef={refVolumeCm3}
            endAdornment={<InputAdornment position='end'>cm³</InputAdornment>}
          />
        </ListItem>
      </List>

      <ProcessingSectionTitle title='Common' />
      <List>
        <ProcessingMethodTitle primary='Unit System' />
        <UnitSystemController />

        <ProcessingMethodTitle primary='Normalization' />
        <NormalizationController refMassMg={refMassMg} refAreaCm2={refAreaCm2} refVolumeCm3={refVolumeCm3} />
      </List>

      <ProcessingSectionTitle title='Hysteresis' />
      <List>
        <ProcessingMethodTitle primary='Offset Correction' />
        <OffsetCorrectionController />

        <ProcessingMethodTitle primary='Drift Correction' />
        <DriftCorrectionController />

        <ProcessingMethodTitle primary='Slope Correction' />
        <SlopeCorrectionController />

        <ProcessingMethodTitle primary='Noise Cancellation' />
        <NoiseCancellationController curveType={CurveType.HYSTERESIS} />
      </List>

      <ProcessingSectionTitle title='IRM' />
      <List>
        <ProcessingMethodTitle primary='Noise Cancellation' />
        <NoiseCancellationController curveType={CurveType.IRM} />
      </List>

      <ProcessingSectionTitle title='Backfield Demagnetization' />
      <List>
        <ProcessingMethodTitle primary='Noise Cancellation' />
        <NoiseCancellationController curveType={CurveType.BACKFIELD_DEMAGNETIZATION} />
      </List>
    </React.Fragment>
  );
};

export default ProcessingMenu;
