import React from 'react';

const UndoRedoDispatcher = React.createContext(null);
const UndoRedoContext = React.createContext(null);

const useUndoRedoCtx = () => React.useContext(UndoRedoContext);
const useUndoRedoDispatcher = () => React.useContext(UndoRedoDispatcher);

const UndoRedoProvider = ({ children }) => {
  const [history, setHistory] = React.useState({ history: [], currentIndex: 0 });

  const execute = React.useCallback((executeFn, undoFn) => {
    const cmd = { executeFn, undoFn };
    setHistory(prevState => {
      const { history, currentIndex } = prevState;
      const newHistory = history.slice(0, currentIndex);

      cmd.executeFn();
      newHistory.push(cmd);

      return { history: newHistory, currentIndex: currentIndex + 1 };
    });
  }, []);

  const undo = () => {
    setHistory(prevState => {
      const { history, currentIndex } = prevState;
      if (currentIndex <= 0) {
        return prevState;
      }
      const lastCmd = history[currentIndex - 1];
      lastCmd.undoFn();
      return { history, currentIndex: currentIndex - 1 };
    });
  };

  const redo = () => {
    setHistory(prevState => {
      const { history, currentIndex } = prevState;
      if (currentIndex >= history.length) {
        return prevState;
      }
      const nextCmd = history[currentIndex];
      nextCmd.executeFn();
      return { history, currentIndex: currentIndex + 1 };
    });
  };

  const canUndo = history.currentIndex > 0;
  const canRedo = history.currentIndex < history.length - 1;

  return (
    <UndoRedoDispatcher.Provider value={execute}>
      <UndoRedoContext.Provider value={{ undo, redo, canUndo, canRedo }}>
        {children}
      </UndoRedoContext.Provider>
    </UndoRedoDispatcher.Provider>
  );
};

export default UndoRedoProvider;

export {
  useUndoRedoCtx,
  useUndoRedoDispatcher,
};
