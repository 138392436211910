/**
 * @param {string} mode
 * @param {string} color
 * @param {Array<float>} xs
 * @param {Array<float>} ys
 * @return {{mode, xaxis: string, line: {color, width: number, simplify: boolean, smoothing: number}, marker: {size: number, color}, showlegend: boolean, x, y, type: string, yaxis: string}}
 */
const buildInsetScatterPlotDataItem = ({
                                         name,
                                         mode,
                                         color,
                                         xs,
                                         ys,
                                       }) => {
  return ({
    name: name,
    showlegend: false,
    x: xs,
    y: ys,
    type: 'scatter',
    mode: mode,
    line: {
      simplify: false,
      smoothing: 0,
      width: 0.9,
      color: color,
    },
    marker: {
      size: 3,
      color: color,
    },
    xaxis: 'x2',
    yaxis: 'y2',
  });
};

export default buildInsetScatterPlotDataItem;
