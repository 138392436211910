import React from 'react';
import fileManagerContextReducer from './file-manager/reducer';
import InitialFileManagerContextState from './file-manager/init';
import FileManagerContextAction from './file-manager/action';

const FileManagerStateContext = React.createContext(null);
const FileManagerDispatchContext = React.createContext(null);

const useFileManagerState = () => React.useContext(FileManagerStateContext);
const useFileManagerDispatcher = () => React.useContext(FileManagerDispatchContext);

const FileManagerProvider = ({ children }) => {
  const [fileManagerState, dispatchFileManagerState] = React.useReducer(fileManagerContextReducer, InitialFileManagerContextState);
  const fileManagerDispatchContextValue = React.useMemo(() => ({
    setSources: (hysteresis, irm, backfield) => dispatchFileManagerState({
      type: FileManagerContextAction.SET_SOURCES,
      hysteresis: hysteresis,
      irm: irm,
      backfield: backfield,
    }),
    setHysteresisSource: (newSource) => dispatchFileManagerState({
      type: FileManagerContextAction.SET_HYSTERESIS_SOURCE,
      source: newSource,
    }),
    setIrmSource: (newSource) => dispatchFileManagerState({
      type: FileManagerContextAction.SET_IRM_SOURCE,
      source: newSource,
    }),
    setBackfieldSource: (newSource) => dispatchFileManagerState({
      type: FileManagerContextAction.SET_BACKFIELD_SOURCE,
      source: newSource,
    }),
    updateHysteresisSource: (newSource) => dispatchFileManagerState({
      type: FileManagerContextAction.UPDATE_HYSTERESIS_SOURCE,
      source: newSource,
    }),
    updateIrmSource: (newSource) => dispatchFileManagerState({
      type: FileManagerContextAction.UPDATE_IRM_SOURCE,
      source: newSource,
    }),
    updateBackfieldSource: (newSource) => dispatchFileManagerState({
      type: FileManagerContextAction.UPDATE_BACKFIELD_SOURCE,
      source: newSource,
    }),
  }), [fileManagerState]);

  return (
    <FileManagerStateContext.Provider value={fileManagerState}>
      <FileManagerDispatchContext.Provider value={fileManagerDispatchContextValue}>
        {children}
      </FileManagerDispatchContext.Provider>
    </FileManagerStateContext.Provider>
  );
};

export default FileManagerProvider;

export {
  useFileManagerState,
  useFileManagerDispatcher,
};
