import React from 'react';
import useHysteresisProcessor from '../hooks/use-hysteresis-processor';
import { useFileManagerState } from './file-manager-provider';
import { useProcessingSpecState } from './processing-spec-provider';

const HysteresisContext = React.createContext(null);
const useHysteresis = () => React.useContext(HysteresisContext);

const Provider = ({ hysteresisSource, commonProcessingSpec, specializedProcessingSpec, children }) => {
  const { processedHysteresis } = useHysteresisProcessor({
    source: hysteresisSource,
    commonProcessingSpec: commonProcessingSpec,
    specializedProcessingSpec: specializedProcessingSpec,
  });

  return (
    <HysteresisContext.Provider value={{
      source: hysteresisSource,
      processedLoop: processedHysteresis,
    }}>
      {children}
    </HysteresisContext.Provider>
  );
};

const withParentContextSelectors = (Provider) => {
  const MemoProvider = React.memo(Provider);

  return ({ children }) => {
    const { hysteresisSource } = useFileManagerState();
    const { common, hysteresis } = useProcessingSpecState();

    return (
      <MemoProvider hysteresisSource={hysteresisSource}
                    commonProcessingSpec={common}
                    specializedProcessingSpec={hysteresis}
                    children={children} />
    );
  };
};

const HysteresisProvider = withParentContextSelectors(Provider);

export default HysteresisProvider;

export {
  useHysteresis,
};
