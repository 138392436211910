import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItem from '@mui/material/ListItem';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useProcessingSpecDispatcher, useProcessingSpecState } from '../../../context/processing-spec-provider';
import { useUndoRedoDispatcher } from '../../../context/undo-redo-provider';
import ProcessingAction from '../../../context/processing-spec/action';
import CurveType from '../../../services/hysteresis-online/common/curve-type';

const supportedNoiseCancellationMethods = {
  '': 'No',
  'whittaker': 'Whittaker',
};

const WhittakerSmoothing = ({ intensity, onIntensityChange }) => {
  return (
    <Tooltip title='Intensity (> 0)' placement='bottom-end'>
      <OutlinedInput
        size='small'
        type='number'
        sx={{ width: '40%', fontSize: '10px' }}
        onBlur={onIntensityChange}
        defaultValue={intensity}
      />
    </Tooltip>
  );
};

const NoiseCancellationControllerInner = React.memo(({ method, onMethodChange, intensitySettings }) => {
  return (
    <ListItem sx={{ padding: '0 5px 0 5px' }}>
      <Select
        size='small'
        value={method}
        onChange={onMethodChange}
        displayEmpty
        sx={{ width: '60%', fontSize: '10px' }}
      >
        {
          Object
            .keys(supportedNoiseCancellationMethods)
            .map((key) => <MenuItem key={key} value={key} dense>{supportedNoiseCancellationMethods[key]}</MenuItem>)
        }
      </Select>
      {method === 'whittaker' && intensitySettings}
    </ListItem>
  );
});

const NoiseCancellationController = ({ curveType }) => {
  const state = useProcessingSpecState();
  const process = useProcessingSpecDispatcher();
  const undoRedo = useUndoRedoDispatcher();

  let noiseCancellationState, action;
  switch (curveType) {
    case CurveType.HYSTERESIS: {
      action = ProcessingAction.SMOOTH_HYSTERESIS_DATA;
      noiseCancellationState = state.hysteresis.noise_cancellation;
      break;
    }
    case CurveType.IRM: {
      action = ProcessingAction.SMOOTH_IRM_DATA;
      noiseCancellationState = state.irm.noise_cancellation;
      break;
    }
    case CurveType.BACKFIELD_DEMAGNETIZATION: {
      action = ProcessingAction.SMOOTH_BACKFIELD_DATA;
      noiseCancellationState = state.backfield_demagnetization.noise_cancellation;
      break;
    }
    default: {
      throw new Error(`unknown type: ${curveType}`);
    }
  }

  const onMethodChange = React.useCallback((event) => {
    event.preventDefault();

    undoRedo(
      () => process({
        type: action,
        method: event.target.value,
        intensity: 0,
      }),
      () => process({
        type: action,
        method: noiseCancellationState.method,
        intensity: noiseCancellationState.intensity,
      }),
    );
  }, [noiseCancellationState]);

  const onIntensityChange = React.useCallback((event) => {
    event.preventDefault();

    let intensity = null;
    const intensityValue = parseFloat(event.target.value);
    if (isFinite(intensityValue)) {
      intensity = intensityValue;
    } else {
      intensity = 0;
    }

    undoRedo(
      () => process({
        type: action,
        method: noiseCancellationState.method,
        intensity: intensity,
      }),
      () => process({
        type: action,
        method: noiseCancellationState.method,
        intensity: noiseCancellationState.intensity,
      }),
    );
  }, [noiseCancellationState]);

  return (
    <NoiseCancellationControllerInner
      method={noiseCancellationState.method}
      onMethodChange={onMethodChange}
      intensitySettings={
        <WhittakerSmoothing intensity={noiseCancellationState.intensity} onIntensityChange={onIntensityChange} />
      }
    />
  );
};

export default NoiseCancellationController;
