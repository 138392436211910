import React from 'react';
import ListItem from '@mui/material/ListItem';
import { MenuItem, Select } from '@mui/material';
import { useProcessingSpecDispatcher, useProcessingSpecState } from '../../../context/processing-spec-provider';
import { useUndoRedoDispatcher } from '../../../context/undo-redo-provider';
import ProcessingAction from '../../../context/processing-spec/action';

const supportedUnitSystem = {
  'si': 'SI',
  'cgs': 'CGS',
  'hybrid': 'Hybrid SI',
  'msi': 'mSI',
  'mhybrid': 'Hybrid mSI',
};

const UnitSystemControllerInner = React.memo(({ currentValue, onChange }) => {
  return (
    <ListItem sx={{ padding: '0 5px 0 5px' }}>
      <Select
        size='small'
        value={currentValue}
        onChange={onChange}
        sx={{ width: '60%', fontSize: '10px' }}
      >
        {
          Object
            .keys(supportedUnitSystem)
            .map((key) => <MenuItem key={key} value={key} dense>{supportedUnitSystem[key]}</MenuItem>)
        }
      </Select>
    </ListItem>
  );
});

const UnitSystemController = () => {
  const state = useProcessingSpecState();
  const process = useProcessingSpecDispatcher();
  const undoRedo = useUndoRedoDispatcher();

  const onChange = React.useCallback((event) => {
    event.preventDefault();
    undoRedo(
      () => process({
        type: ProcessingAction.SWITCH_UNIT_SYSTEM,
        unit_system: event.target.value,
      }),
      () => process({
        type: ProcessingAction.SWITCH_UNIT_SYSTEM,
        unit_system: state.common.unit_system,
      }),
    );
  }, [state.common.unit_system]);

  return (
    <UnitSystemControllerInner currentValue={state.common.unit_system} onChange={onChange} />
  );
};

export default UnitSystemController;
