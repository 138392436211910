import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Fab from '@mui/material/Fab';
import AppName from '../components/titles/app-name';
import PlotSettings from '../modules/plot/plot-settings';
import PlotMenu from '../modules/app-bar/plot-menu';
import Workspace from '../modules/workspace/workspace';
import FileMenu from '../modules/app-bar/file-menu';
import ExportDataMenu from '../modules/app-bar/export-data-menu';
import AppProvider from '../context/app-provider';
import { useUndoRedoCtx } from '../context/undo-redo-provider';

const drawerWidth = 250;

const UndoButton = () => {
  const undoRedo = useUndoRedoCtx();

  return (
    <Fab sx={{
      margin: 0,
      top: 'auto',
      bottom: 20,
      left: drawerWidth + 20,
      position: 'fixed',
    }}
         variant='extended'
         color='primary'
         onClick={undoRedo.undo}
         disabled={!undoRedo.canUndo}>
      UNDO
    </Fab>
  );
};

const MainPage = () => {
  const [plotSettings, setPlotSettings] = React.useState(PlotSettings.default());

  return (
    <AppProvider>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position='fixed'
          sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
        >
          <Container maxWidth='xl'>
            <Toolbar disableGutters>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <FileMenu />
                <PlotMenu plotSettings={plotSettings} setPlotSettings={setPlotSettings} />
                <ExportDataMenu />
              </Box>
              <AppName />
            </Toolbar>
          </Container>
        </AppBar>
        <Workspace plotSettings={plotSettings} />
        <UndoButton />
      </Box>
    </AppProvider>
  );
};

export default MainPage;
