import Icons from './icons';
import Plotly from 'plotly.js-basic-dist-min';

const Buttons = {
  exportAsSvgButton: {
    name: 'Download plot as a svg',
    icon: Icons.exportAsSvg,
    click: function(gd) {
      Plotly.downloadImage(gd, {
        format: 'svg',
        width: gd._fullLayout.width,
        height: gd._fullLayout.height,
      });
    },
  },
  exportAsPngButton: {
    name: 'Download plot as a png',
    icon: Icons.exportAsPng,
    direction: 'down',
    click: function(gd) {
      Plotly.downloadImage(gd, {
        format: 'png',
        width: gd._fullLayout.width,
        height: gd._fullLayout.height,
      });
    },
  },
};

export default Buttons;
