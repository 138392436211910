import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import ProcessingMethodTitle from '../../../components/titles/processing-method-title';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { FormLabel } from '@mui/material';

const InsetPlotExtraInputs = ({ control }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', height: '100%' }}>
      <FormControl sx={{ m: 1 }} size='small'>
        <InputLabel htmlFor='inset-plot-field-ratio'>Field</InputLabel>
        <Controller
          name='insetPlotFieldRatio'
          control={control}
          render={({
                     field: { onChange, value },
                   }) => (
            <OutlinedInput
              id='inset-plot-field-ratio'
              endAdornment={<InputAdornment position='end'>%</InputAdornment>}
              onChange={onChange}
              value={value}
              label='Field'
              type='number'
            />)}
        />
      </FormControl>
      <FormControl sx={{ m: 1 }} size='small'>
        <InputLabel htmlFor='inset-plot-moment-ratio'>Moment</InputLabel>
        <Controller
          name='insetPlotMomentRatio'
          control={control}
          render={({
                     field: { onChange, value },
                   }) => (
            <OutlinedInput
              id='inset-plot-moment-ratio'
              endAdornment={<InputAdornment position='end'>%</InputAdornment>}
              onChange={onChange}
              value={value}
              label='Moment'
              type='number'
            />)}
        />
      </FormControl>
    </Box>
  );
};

const LegendLabel = ({ id, name, control }) => {
  return (
    <FormControl sx={{ m: 1 }} size='small'>
      <InputLabel htmlFor={id}>Display Name</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({
                   field: { onChange, value },
                 }) => (
          <OutlinedInput
            id={id}
            onChange={onChange}
            value={value}
            label='Display Name'
            type='text'
          />)}
      />
    </FormControl>
  );
};

const InsetPlotSettings = ({ control, field }) => {
  const showInsetPlot = useWatch({
    control,
    name: 'showInsetPlot',
  });

  return (
    <FormGroup row>
      <FormControlLabel
        control={<Checkbox size='small' checked={showInsetPlot} {...field} />}
        label={<ProcessingMethodTitle primary='Inset plot' />}
        labelPlacement='start'
      />
      {showInsetPlot && (
        <InsetPlotExtraInputs control={control} />
      )}
    </FormGroup>
  );
};

const SlopeTangentSettings = ({ control, field }) => {
  const showSlopeTangent = useWatch({
    control,
    name: 'showSlopeTangent',
  });

  return (
    <FormGroup row>
      <FormControlLabel
        control={<Checkbox size='small' checked={showSlopeTangent} {...field} />}
        label={<ProcessingMethodTitle primary='Slope Tangent' />}
        labelPlacement='start'
      />
      {showSlopeTangent && (
        <LegendLabel id='slope-tangent-display-name' name='slopeTangentDisplayName' control={control} />
      )}
    </FormGroup>
  );
};

const HcrLinearSettings = ({ control, field }) => {
  const showHcrLinear = useWatch({
    control,
    name: 'showHcrLinear',
  });

  return (
    <FormGroup row>
      <FormControlLabel
        control={<Checkbox size='small' checked={showHcrLinear} {...field} />}
        label={<ProcessingMethodTitle primary='Hcr Linear' />}
        labelPlacement='start'
      />
      {showHcrLinear && (
        <LegendLabel id='hcr-display-name' name='hcrLinearDisplayName' control={control} />
      )}
    </FormGroup>
  );
};

/**
 * @param {ProcessedCurvesPlotSettingsData} settings
 * @param  onUpdate
 * @return {JSX.Element}
 * @constructor
 */
const ProcessedCurvesPlotSettings = ({ settings, onUpdate }) => {
  const methods = useForm({
    defaultValues: {
      hysteresisDisplayName: settings.hysteresisDisplayName,
      irmDisplayName: settings.irmDisplayName,
      backfieldDisplayName: settings.backfieldDisplayName,

      showInsetPlot: settings.insetPlot.show,
      insetPlotFieldRatio: settings.insetPlot.fieldRatio,
      insetPlotMomentRatio: settings.insetPlot.momentRatio,

      showSlopeTangent: settings.slopeTangent.show,
      slopeTangentDisplayName: settings.slopeTangent.displayName,

      showHcrLinear: settings.hcrLinear.show,
      hcrLinearDisplayName: settings.hcrLinear.displayName,
    },
  });
  const { handleSubmit, control } = methods;
  const onSubmit = (data) => onUpdate(data);

  return (
    <Box width='100%' height='100%' display='flex' flexDirection='column'
         sx={{ padding: '10px', bgcolor: 'background.paper' }}>
      <Controller
        name='showInsetPlot'
        control={control}
        render={({ field }) => <InsetPlotSettings field={field} control={control} />}
      />
      <Controller
        name='showSlopeTangent'
        control={control}
        render={({ field }) => <SlopeTangentSettings field={field} control={control} />}
      />
      <Controller
        name='showHcrLinear'
        control={control}
        render={({ field }) => <HcrLinearSettings field={field} control={control} />}
      />
      <Button onClick={handleSubmit(onSubmit)}>
        {' '}
        OK{' '}
      </Button>
    </Box>
  );
};

export default ProcessedCurvesPlotSettings;
