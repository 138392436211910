class MagneticPropsData {
  /**
   * @param {HysteresisMagneticProperties} [hysteresis]
   * @param {IrmMagneticProperties} [irm]
   * @param {BackfieldDemagnetizationMagneticProperties} [backfieldDemagnetization]
   */
  constructor({
                hysteresis,
                irm,
                backfieldDemagnetization,
              }) {
    this.hysteresis = hysteresis;
    this.irm = irm;
    this.backfieldDemagnetization = backfieldDemagnetization;
  }

  /**
   * @return {Array<{name: string, value: string}>}
   */
  entries() {
    return [
      {
        name: '$H_c$',
        value: this.hysteresis === null ? '' : this.hysteresis.coercivity_field,
      },
      {
        name: '$H_{cr}$',
        value: this.backfieldDemagnetization === null ? '' : this.backfieldDemagnetization.coercivity_field,
      },
      {
        name: '$M_{rs}$',
        value: this.hysteresis === null ? '' : this.hysteresis.saturation_remanence_moment,
      },
      {
        name: '$H_s$',
        value: this.hysteresis === null ? '' : this.hysteresis.saturation_field,
      },
      {
        name: '$M_s$',
        value: this.hysteresis === null ? '' : this.hysteresis.saturation_moment,
      },
    ];
  }

  static empty() {
    return new MagneticPropsData({
      hysteresis: null,
      irm: null,
      backfieldDemagnetization: null,
    });
  }
}

export default MagneticPropsData;
