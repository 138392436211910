import React from 'react';
import ListItem from '@mui/material/ListItem';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useProcessingSpecDispatcher, useProcessingSpecState } from '../../../context/processing-spec-provider';
import { useUndoRedoDispatcher } from '../../../context/undo-redo-provider';
import ProcessingAction from '../../../context/processing-spec/action';

const supportedSlopeCorrectionMethods = {
  '': 'No',
  'linear_hf': 'Linear High Fields',
};

const LinearHighfieldCorrection = ({ satField, onSatFieldChange }) => {
  return (
    <Tooltip title='Saturation Field' placement='bottom-end'>
      <OutlinedInput
        size='small'
        type='number'
        sx={{ width: '40%', fontSize: '10px' }}
        value={satField}
        onBlur={onSatFieldChange}
      />
    </Tooltip>
  );
};

const SlopeCorrectionControllerInner = React.memo(({ method, onMethodChange, linearHighfieldSettings }) => {
  return (
    <ListItem sx={{ padding: '0 5px 0 5px' }}>
      <Select
        size='small'
        value={method}
        onChange={onMethodChange}
        displayEmpty
        sx={{ width: '60%', fontSize: '10px' }}
      >
        {
          Object
            .keys(supportedSlopeCorrectionMethods)
            .map((key) => <MenuItem key={key} value={key} dense>{supportedSlopeCorrectionMethods[key]}</MenuItem>)
        }
      </Select>
      {method === 'linear_hf' && linearHighfieldSettings}
    </ListItem>
  );
});

const SlopeCorrectionController = () => {
  const state = useProcessingSpecState();
  const process = useProcessingSpecDispatcher();
  const undoRedo = useUndoRedoDispatcher();

  const onMethodChange = React.useCallback((event) => {
    event.preventDefault();

    undoRedo(
      () => process({
        type: ProcessingAction.CORRECT_SLOPE,
        method: event.target.value,
        saturation_field: null,
      }),
      () => process({
        type: ProcessingAction.CORRECT_SLOPE,
        method: state.hysteresis.slope_correction.method,
        saturation_field: state.hysteresis.slope_correction.saturation_field,
      }),
    );
  }, [state.hysteresis.slope_correction]);

  const onSaturationFieldChange = React.useCallback((event) => {
    event.preventDefault();
    let saturationField = null;
    const saturationFieldValue = parseFloat(event.target.value);
    if (isFinite(saturationFieldValue)) {
      saturationField = saturationFieldValue;
    }

    undoRedo(
      () => process({
        type: ProcessingAction.CORRECT_SLOPE,
        method: state.hysteresis.slope_correction.method,
        saturation_field: saturationField,
      }),
      () => process({
        type: ProcessingAction.CORRECT_SLOPE,
        method: state.hysteresis.slope_correction.method,
        saturation_field: state.hysteresis.slope_correction.saturation_field,
      }),
    );
  }, [state.hysteresis.slope_correction]);

  return (
    <SlopeCorrectionControllerInner
      method={state.hysteresis.slope_correction.method}
      onMethodChange={onMethodChange}
      linearHighfieldSettings={
        <LinearHighfieldCorrection satField={state.hysteresis.slope_correction.saturation_field}
                                   onSaturationFieldChange={onSaturationFieldChange} />
      }
    />
  );
};

export default SlopeCorrectionController;
