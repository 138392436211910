class PlotSettings {
  /**
   * @param {boolean} showMarkers
   * @param {boolean} showLines
   */
  constructor(showMarkers, showLines) {
    this.showMarkers = showMarkers;
    this.showLines = showLines;
  }

  plotlyMode() {
    if (this.showLines && this.showMarkers) {
      return 'lines+markers';
    } else if (this.showLines) {
      return 'lines';
    } else if (this.showMarkers) {
      return 'markers';
    } else {
      return 'none';
    }
  }

  copy() {
    return new PlotSettings(this.showMarkers, this.showLines);
  }

  static default() {
    return new PlotSettings(false, true);
  }
}

export default PlotSettings;
