import React from 'react';
import useBackfieldProcessor from '../hooks/use-backfield-processor';
import { useFileManagerState } from './file-manager-provider';
import { useProcessingSpecState } from './processing-spec-provider';


const BackfieldContext = React.createContext(null);

const useBackfield = () => React.useContext(BackfieldContext);

const Provider = ({ backfieldSource, commonProcessingSpec, specializedProcessingSpec, children }) => {
  const { processedBackfield } = useBackfieldProcessor({
    source: backfieldSource,
    commonProcessingSpec: commonProcessingSpec,
    specializedProcessingSpec: specializedProcessingSpec,
  });

  return (
    <BackfieldContext.Provider value={{
      source: backfieldSource,
      processedBackfield: processedBackfield,
    }}>
      {children}
    </BackfieldContext.Provider>
  );
};

const withParentContextSelectors = (Provider) => {
  const MemoProvider = React.memo(Provider);

  return ({ children }) => {
    const { backfieldSource } = useFileManagerState();
    const { common, backfield_demagnetization } = useProcessingSpecState();

    return (
      <MemoProvider backfieldSource={backfieldSource}
                    commonProcessingSpec={common}
                    specializedProcessingSpec={backfield_demagnetization}
                    children={children} />
    );
  };
};

const BackfieldProvider = withParentContextSelectors(Provider);

export default BackfieldProvider;

export {
  useBackfield,
};
