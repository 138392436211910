import React from 'react';
import useIrmProcessor from '../hooks/use-irm-processor';
import { useProcessingSpecState } from './processing-spec-provider';
import { useFileManagerState } from './file-manager-provider';

const IrmContext = React.createContext(null);

const useIrm = () => React.useContext(IrmContext);

const Provider = ({ irmSource, commonProcessingSpec, specializedProcessingSpec, children }) => {
  const { processedIrm } = useIrmProcessor({
    source: irmSource,
    commonProcessingSpec: commonProcessingSpec,
    specializedProcessingSpec: specializedProcessingSpec,
  });

  return (
    <IrmContext.Provider value={{
      source: irmSource,
      processedIrm: processedIrm,
    }}>
      {children}
    </IrmContext.Provider>
  );
};

const withParentContextSelectors = (Provider) => {
  const MemoProvider = React.memo(Provider);

  return ({ children }) => {
    const { irmSource } = useFileManagerState();
    const { common, irm } = useProcessingSpecState();

    return (
      <MemoProvider irmSource={irmSource}
                    commonProcessingSpec={common}
                    specializedProcessingSpec={irm}
                    children={children} />
    );
  };
};

const IrmProvider = withParentContextSelectors(Provider);

export default IrmProvider;

export {
  useIrm,
};
