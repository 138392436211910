export class IrmMagneticProperties {
  /**
   * @param {float} saturation_remanence_moment
   * @param {float} saturation_field
   */
  constructor({
                saturation_remanence_moment,
                saturation_field,
              }) {
    this.saturation_remanence_moment = saturation_remanence_moment;
    this.saturation_field = saturation_field;
  }
}


export class ProcessedIrm {
  /**
   * @param {IrmMagneticProperties} magnetic_props
   * @param {Array<float>} fields
   * @param {Array<float>} remanence_moments
   * @param {string} field_units
   * @param {string} moment_units
   */
  constructor({
                magnetic_props,
                fields,
                remanence_moments,
                field_units,
                moment_units,
              }) {
    this.magnetic_props = magnetic_props;
    this.fields = fields;
    this.remanence_moments = remanence_moments;
    this.field_units = field_units;
    this.moment_units = moment_units;
  }

  /**
   * @return {Array<Point>}
   */
  points() {
    const data = [];
    for (let i = 0; i < this.fields.length; i++) {
      data.push({
        field: this.fields[i],
        moment: this.remanence_moments[i],
      });
    }
    return data;
  }
}
