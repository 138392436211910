import React from 'react';
import ListItem from '@mui/material/ListItem';
import { MenuItem, Select } from '@mui/material';
import { useProcessingSpecDispatcher, useProcessingSpecState } from '../../../context/processing-spec-provider';
import { useUndoRedoDispatcher } from '../../../context/undo-redo-provider';
import ProcessingAction from '../../../context/processing-spec/action';

const supportedNormalizationMethods = {
  '': 'No',
  'mass': 'Mass',
  'area': 'Area',
  'volume': 'Volume',
};

const getValue = (method, refMassMg, refAreaCm2, refVolumeCm3) => {
  switch (method) {
    case '':
      return null;
    case 'mass':
      return refMassMg.current.value;
    case 'area':
      return refAreaCm2.current.value;
    case 'volume':
      return refVolumeCm3.current.value;
    default:
      throw new Error(`unknown normalization method: ${method}`);
  }
};

const NormalizationControllerInner = React.memo(({ currentValue, onChange }) => {
  return (
    <ListItem sx={{ padding: '0 5px 0 5px' }}>
      <Select
        size='small'
        value={currentValue}
        onChange={onChange}
        sx={{ width: '60%', fontSize: '10px' }}
      >
        {
          Object
            .keys(supportedNormalizationMethods)
            .map((key) => <MenuItem key={key} value={key} dense>{supportedNormalizationMethods[key]}</MenuItem>)
        }
      </Select>
    </ListItem>
  );
});

const NormalizationController = ({ refMassMg, refAreaCm2, refVolumeCm3 }) => {
  const state = useProcessingSpecState();
  const process = useProcessingSpecDispatcher();
  const undoRedo = useUndoRedoDispatcher();

  const onChange = React.useCallback((event) => {
    event.preventDefault();

    undoRedo(
      () => process({
        type: ProcessingAction.NORMALIZE,
        method: event.target.value,
        value: getValue(event.target.value, refMassMg, refAreaCm2, refVolumeCm3),
      }),
      () => process({
        type: ProcessingAction.NORMALIZE,
        method: state.common.normalization.method,
        value: state.common.normalization.value,
      }),
    );
  }, [state.common.normalization]);

  return (
    <NormalizationControllerInner currentValue={state.common.normalization.method} onChange={onChange} />
  );
};

export default NormalizationController;
