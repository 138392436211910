import InitialFileManagerContextState from './init';
import FileManagerContextAction from './action';

const fileManagerContextReducer = (currentState, action) => {
  switch (action.type) {
    case FileManagerContextAction.SET_SOURCES:
      return {
        hysteresisSource: action.hysteresis,
        irmSource: action.irm,
        backfieldSource: action.backfield,
      };
    case FileManagerContextAction.SET_HYSTERESIS_SOURCE:
      if (action.source === currentState.hysteresisSource && !currentState.irmSource && !currentState.backfieldSource) {
        return currentState;
      }
      return {
        ...InitialFileManagerContextState,
        hysteresisSource: action.source,
      };
    case FileManagerContextAction.UPDATE_HYSTERESIS_SOURCE:
      if (action.source === currentState.hysteresisSource) {
        return currentState;
      }
      return {
        ...currentState,
        hysteresisSource: action.source,
      };
    case FileManagerContextAction.SET_IRM_SOURCE:
      if (action.source === currentState.irmSource && !currentState.hysteresisSource && !currentState.backfieldSource) {
        return currentState;
      }
      return {
        ...InitialFileManagerContextState,
        irmSource: action.source,
      };
    case FileManagerContextAction.UPDATE_IRM_SOURCE:
      if (action.source === currentState.irmSource) {
        return currentState;
      }
      return {
        ...currentState,
        irmSource: action.source,
      };
    case FileManagerContextAction.SET_BACKFIELD_SOURCE:
      if (action.source === currentState.backfieldSource && !currentState.hysteresisSource && !currentState.irmSource) {
        return currentState;
      }
      return {
        ...InitialFileManagerContextState,
        backfieldSource: action.source,
      };
    case FileManagerContextAction.UPDATE_BACKFIELD_SOURCE:
      if (action.source === currentState.backfieldSource) {
        return currentState;
      }
      return {
        ...currentState,
        backfieldSource: action.source,
      };
    default:
      return currentState;
  }
};

export default fileManagerContextReducer;
