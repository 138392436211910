import React from 'react';
import Plot from '../../../components/plot/plot';
import buildLayout from '../../../components/plot/plot-layout';
import buildScatterPlotDataItem from '../../../components/plot/plot-data-item';
import HysteresisNoisePlotData from './hysteresis-noise-plot-models';
import { useHysteresis } from '../../../context/hysteresis-provider';

/**
 * @param {HysteresisNoisePlotData} hysteresis
 * @return {{maxMoment: number, maxField: number}}
 */
const extractMaxAxisValues = (hysteresis) => {
  let maxField = 0, maxMoment = 0;
  if (!hysteresis.isEmpty()) {
    maxField = Math.max(
      Math.abs(hysteresis.fields[0]),
      Math.abs(hysteresis.fields[hysteresis.fields.length - 1]),
    );
    maxMoment = Math.max.apply(null, hysteresis.moments.map(Math.abs));
  }

  return {
    maxField,
    maxMoment,
  };
};

/**
 * @param {PlotSettings} settings
 * @param {HysteresisNoisePlotData} data
 * @return {JSX.Element}
 * @constructor
 */
const HysteresisNoisePlot = ({ settings }) => {
  const { processedLoop } = useHysteresis();
  const data = processedLoop ? HysteresisNoisePlotData.fromProcessedHysteresisLoop(processedLoop) : HysteresisNoisePlotData.empty();
  const {maxField, maxMoment} = extractMaxAxisValues(data);

  return (
    <Plot
      divId='plotlyChart'
      data={[
        buildScatterPlotDataItem({
          mode: settings.plotlyMode(),
          name: '$\\text{Noise}$',
          xs: data.fields,
          ys: data.moments,
        }),
      ]}
      layout={buildLayout('$\\text{Noise}$', data.fieldUnits, data.momentUnits, 1.15 * maxField, 1.15 * maxMoment)}
      style={{ width: '100%', height: '300px' }}
      config={{ displayModeBar: false }}
    />
  );
};

export default HysteresisNoisePlot;
