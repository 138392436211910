import React from 'react';
import FileSaver from 'file-saver';
import { Button, Fade, ListItemText, Menu, MenuItem } from '@mui/material';
import { useHysteresis } from '../../context/hysteresis-provider';
import { useIrm } from '../../context/irm-provider';
import { useBackfield } from '../../context/backfield-provider';

/**
 * @param {Array<{field: number, moment: number}>} data
 * @param {string} fieldUnits
 * @param {string} momentUnits
 * @return {Blob}
 */
function createCsv({
                     data,
                     fieldUnits,
                     momentUnits,
                   }) {
  const columnSep = ';';
  const lineSep = '\n';

  const header = `Field(${fieldUnits})${columnSep}Moment(${momentUnits})${lineSep}`;
  const body = data
    .map(({ field, moment }) => `${field.toExponential()}${columnSep}${moment.toExponential()}`)
    .join(lineSep);

  return new Blob([header.concat(body)], { type: 'text/csv;charset=utf-8;' });
}

const ExportHysteresisData = () => {
  const { source, processedLoop } = useHysteresis();

  const handleClick = () => {
    const data = processedLoop.originalLoopPoints();

    const csv = createCsv({
      data: data,
      fieldUnits: processedLoop.field_units,
      momentUnits: processedLoop.moment_units,
    });
    FileSaver.saveAs(csv, `${source}.csv`);
  };

  return (
    <MenuItem disabled={processedLoop === null} onClick={handleClick} dense>
      <ListItemText>Hysteresis</ListItemText>
    </MenuItem>);
};

const ExportIrmData = () => {
  const { source, processedIrm } = useIrm();

  const handleClick = () => {
    const data = processedIrm.points();

    const csv = createCsv({
      data: data,
      fieldUnits: processedIrm.field_units,
      momentUnits: processedIrm.moment_units,
    });
    FileSaver.saveAs(csv, `${source}.csv`);
  };

  return (
    <MenuItem disabled={processedIrm === null} onClick={handleClick} dense>
      <ListItemText>IRM</ListItemText>
    </MenuItem>);
};

const ExportBackfieldData = () => {
  const { source, processedBackfield } = useBackfield();

  const handleClick = () => {
    const data = processedBackfield.points();

    const csv = createCsv({
      data: data,
      fieldUnits: processedBackfield.field_units,
      momentUnits: processedBackfield.moment_units,
    });
    FileSaver.saveAs(csv, `${source}.csv`);
  };

  return (
    <MenuItem disabled={processedBackfield === null} onClick={handleClick} dense>
      <ListItemText>Backfield</ListItemText>
    </MenuItem>);
};

const ExportDataMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Button
        id='export-data-menu'
        onClick={(event) => setAnchorEl(event.currentTarget)}
        sx={{ my: 2, color: 'white', display: 'block' }}
      >
        EXPORT
      </Button>
      <Menu
        id='fade-export-data-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
      >
        <ExportHysteresisData />
        <ExportIrmData />
        <ExportBackfieldData />
      </Menu>
    </React.Fragment>
  );
};

export default ExportDataMenu;
