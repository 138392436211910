import React from 'react';
import FileSaver from 'file-saver';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import { Button, Tab, Tabs } from '@mui/material';
import { useHysteresis } from '../../context/hysteresis-provider';
import { useIrm } from '../../context/irm-provider';
import { useBackfield } from '../../context/backfield-provider';


/**
 * @param {Array<{field: number, moment: number}>} data
 * @param {string} fieldUnits
 * @param {string} momentUnits
 * @return {Blob}
 */
function createCsv({
                     data,
                     fieldUnits,
                     momentUnits,
                   }) {
  const columnSep = ';';
  const lineSep = '\n';

  const header = `Field(${fieldUnits})${columnSep}Moment(${momentUnits})${lineSep}`;
  const body = data
    .map(({ field, moment }) => `${field.toExponential()}${columnSep}${moment.toExponential()}`)
    .join(lineSep);

  return new Blob([header.concat(body)], { type: 'text/csv;charset=utf-8;' });
}

const ParsedDataTableImpl = ({
                               fieldUnits,
                               momentUnits,
                               points,
                             }) => {
  return (
    <TableContainer sx={{ height: '150px', overflow: 'auto' }} component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='center' sx={{ fontSize: '12px' }}>
              H ({fieldUnits})
            </TableCell>
            <TableCell align='center' sx={{ fontSize: '12px' }}>
              M ({momentUnits})
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {points.map((point, index) => (
            <TableRow key={index}>
              <TableCell align='center' sx={{ fontSize: '12px' }}>{point.field}</TableCell>
              <TableCell align='center' sx={{ fontSize: '12px' }}>{point.moment}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ParsedDataTable = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { source: hysteresisSource, processedLoop } = useHysteresis();
  const { source: irmSource, processedIrm } = useIrm();
  const { source: backfieldSource, processedBackfield } = useBackfield();

  const handleClick = () => {
    let source = '';
    let data = null;
    if (processedLoop && selectedTab === 0) {
      source = hysteresisSource;
      data = {
        data: processedLoop.originalLoopPoints(),
        fieldUnits: processedLoop.field_units,
        momentUnits: processedLoop.moment_units,
      };
    } else if (processedIrm && selectedTab === 1) {
      source = irmSource;
      data = {
        data: processedIrm.points(),
        fieldUnits: processedIrm.field_units,
        momentUnits: processedIrm.moment_units,
      };
    } else if (processedBackfield && selectedTab === 2) {
      source = backfieldSource;
      data = {
        data: processedBackfield.points(),
        fieldUnits: processedBackfield.field_units,
        momentUnits: processedBackfield.moment_units,
      };
    } else {
      alert('Nothing to export');
      return;
    }

    const csv = createCsv(data);
    FileSaver.saveAs(csv, `${source}.csv`);
  };

  return (
    <>
      <Box sx={{ height: '50px', width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={selectedTab}
              onChange={(event, newValue) => setSelectedTab(newValue)}
              variant='fullWidth'>
          <Tab label='Hysteresis' sx={{ fontSize: '10px' }} disabled={hysteresisSource === null} />
          <Tab label='IRM' sx={{ fontSize: '10px' }} disabled={irmSource === null} />
          <Tab label='Backfield' sx={{ fontSize: '10px' }} disabled={backfieldSource === null} />
        </Tabs>
      </Box>
      {processedLoop && selectedTab === 0 &&
        <ParsedDataTableImpl
          fieldUnits={processedLoop.field_units}
          momentUnits={processedLoop.moment_units}
          points={processedLoop.originalLoopPoints()}
        />
      }
      {processedIrm && selectedTab === 1 &&
        <ParsedDataTableImpl
          fieldUnits={processedIrm.field_units}
          momentUnits={processedIrm.moment_units}
          points={processedIrm.points()}
        />
      }
      {processedBackfield && selectedTab === 2 &&
        <ParsedDataTableImpl
          fieldUnits={processedBackfield.field_units}
          momentUnits={processedBackfield.moment_units}
          points={processedBackfield.points()}
        />
      }
      <Box display='flex' justifyContent='center' sx={{ height: '50px', width: '100%' }}>
        <Button onClick={handleClick}>
          EXPORT
        </Button>
      </Box>

    </>
  );
};

export default ParsedDataTable;
