import React from 'react';
import ListItem from '@mui/material/ListItem';
import { MenuItem, Select } from '@mui/material';
import { useProcessingSpecDispatcher, useProcessingSpecState } from '../../../context/processing-spec-provider';
import { useUndoRedoDispatcher } from '../../../context/undo-redo-provider';
import ProcessingAction from '../../../context/processing-spec/action';

const supportedDriftCorrectionMethods = {
  '': 'No',
  'positive_fields': 'Positive Fields',
  'upper_branch': 'Upper Branch',
  'symmetric_averaging': 'Symmetric Averaging',
};

const DriftCorrectionControllerInner = React.memo(({ currentValue, onChange }) => {
  return (
    <ListItem sx={{ padding: '0 5px 0 5px' }}>
      <Select
        size='small'
        value={currentValue}
        onChange={onChange}
        displayEmpty
        sx={{ width: '60%', fontSize: '10px' }}
      >
        {
          Object
            .keys(supportedDriftCorrectionMethods)
            .map((key) => <MenuItem key={key} value={key} dense>{supportedDriftCorrectionMethods[key]}</MenuItem>)
        }
      </Select>
    </ListItem>
  );
});

const DriftCorrectionController = () => {
  const state = useProcessingSpecState();
  const process = useProcessingSpecDispatcher();
  const undoRedo = useUndoRedoDispatcher();

  const onChange = React.useCallback((event) => {
    event.preventDefault();

    undoRedo(
      () => process({
        type: ProcessingAction.CORRECT_DRIFT,
        method: event.target.value,
      }),
      () => process({
        type: ProcessingAction.CORRECT_DRIFT,
        method: state.hysteresis.drift_correction.method,
      }),
    );
  }, [state.hysteresis.drift_correction]);

  return (
    <DriftCorrectionControllerInner currentValue={state.hysteresis.drift_correction.method} onChange={onChange} />
  );
};

export default DriftCorrectionController;
