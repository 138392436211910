import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ProcessingStatsData from './processing-stats-models';
import { useHysteresis } from '../../context/hysteresis-provider';

/**
 * @return {JSX.Element}
 * @constructor
 */
const ProcessingStats = () => {
  const { processedLoop } = useHysteresis();
  const props = new ProcessingStatsData({
    hysteresis: processedLoop ? processedLoop.stats : null,
  });

  return (
    <TableContainer sx={{ marginY: '5px' }} component={Paper}>
      <Table size='small' aria-label='a dense table'>

        <TableBody>
          {props.entries().map(({ name, value }) => (
            <TableRow
              key={name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component='th' scope='row'>
                {name}
              </TableCell>
              <TableCell align='center'>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProcessingStats;
