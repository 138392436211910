import UnprocessedMeasuredData from './unprocessed-measured-data-models';
import ApiError from '../common/api-error';

/**
 * @param {string} curveType
 * @param {Blob} file
 * @return {Promise<UnprocessedMeasuredData>}
 */
const uploadFile = (curveType, file) => {
  const formData = new FormData();
  formData.append('file', file);

  return fetch(`http://65.109.26.107/api/specimen/measurement/file/upload?measured_curve=${curveType}`, {
    method: 'POST',
    body: formData,
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((parsedError) => {
        throw new ApiError(response.status, parsedError.message);
      });
    }
    return response.json();
  }).then((parsedBody) => new UnprocessedMeasuredData(parsedBody));
};

export default uploadFile;
