import React from 'react';
import UnprocessedDataStorage from '../storage/unprocessed-data-storage';
import CurveType from '../services/hysteresis-online/common/curve-type';
import processBackfieldDemagnetizationV2
  from '../services/hysteresis-online/processing/process-backfield-demagnetization-v2';

/**
 * @type {{
 *   status: string,
 *   error: string|null,
 *   processedBackfield: ProcessedBackfieldDemagnetization|null
 * }}
 */
const initialState = {
  status: 'idle',
  error: null,
  processedBackfield: null,
};

/**
 * @param {string|null} fileName
 * @return {{
 *  source: string,
 *  unprocessedData: UnprocessedMeasuredData
 * }|null}
 */
const fetchBackfieldFromStorage = (fileName) => {
  if (!fileName) {
    return null;
  }

  let data = UnprocessedDataStorage.get(fileName, CurveType.BACKFIELD_DEMAGNETIZATION);
  if (!data) {
    data = UnprocessedDataStorage.get(fileName, CurveType.IRM_AND_DCD);
  }
  if (!data) {
    data = UnprocessedDataStorage.get(fileName, CurveType.HYSTERESIS_IRM_DCD);
  }
  if (!data) {
    return null;
  }
  return {
    source: data.name,
    unprocessedData: data.unprocessed_data,
  };
};

/**
 *
 * @param {string|null} source
 * @param {CommonProcessingSpec} commonProcessingSpec
 * @param {RemanenceCurveProcessingSpec} specializedProcessingSpec
 * @return {{
 *   status: string,
 *   error: string|null,
 *   processedBackfield: ProcessedBackfieldDemagnetization|null
 * }}
 */
const useBackfieldProcessor = ({
                                 source,
                                 commonProcessingSpec,
                                 specializedProcessingSpec,
                               }) => {
  const cache = React.useRef({});
  const backfield = React.useMemo(() => fetchBackfieldFromStorage(source), [source]);
  const [processedData, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case 'RESET':
        return initialState;
      case 'PROCESSING':
        return { ...initialState, status: 'processing' };
      case 'PROCESSED':
        return { ...initialState, status: 'processed', processedBackfield: action.payload };
      case 'PROCESSING_ERROR':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);

  React.useEffect(() => {
      let cancelRequest = false;
      if (!backfield) {
        dispatch({ type: 'RESET' });
        return;
      }

      const processData = async () => {
        dispatch({ type: 'PROCESSING' });
        if (cache.current[backfield.source]) {
          const data = cache.current[backfield.source];
          dispatch({ type: 'PROCESSED', payload: data });
        } else {
          try {
            const data = await processBackfieldDemagnetizationV2(
              commonProcessingSpec,
              specializedProcessingSpec,
              backfield.unprocessedData.getRawBackfieldData(),
            );
            // cache.current[backfield.source] = data; TODO
            if (cancelRequest) return;
            dispatch({ type: 'PROCESSED', payload: data });
          } catch (error) {
            if (cancelRequest) return;
            dispatch({ type: 'PROCESSING_ERROR', payload: error.message });
          }
        }
      };

      processData();

      return function cleanup() {
        cancelRequest = true;
      };
    },
    [backfield, commonProcessingSpec, specializedProcessingSpec],
  );

  return {
    unprocessedIrm: backfield?.unprocessedData,
    processedBackfield: processedData.processedBackfield,
  };
};

export default useBackfieldProcessor;
