const buildLayout = (title, xAxisLabel, yAxisLabel, maxX, maxY) => {
  let xAxisLayout = {
    title: {
      text: xAxisLabel,
      standoff: 0,
    },
    linecolor: 'black',
    mirror: true,
    linewidth: 1,
    nticks: 10,
    range: [-maxX, maxX],
  };
  if (maxX >= 1000 || maxX < 0.01) {
    xAxisLayout = {
      ...xAxisLayout,
      showexponent: 'all',
      exponentformat: 'e',
      tickformat: '.1e',
    };
  } else {
    xAxisLayout = {
      ...xAxisLayout,
      tickformat: '.1f',
    };
  }

  return ({
    title: title,
    autosize: true,
    margin: { t: 30 },
    xaxis: xAxisLayout,
    yaxis: {
      title: yAxisLabel,
      showexponent: 'all',
      exponentformat: 'e',
      linecolor: 'black',
      mirror: true,
      linewidth: 1,
      tickformat: '.1e',
      rangemode: 'tozero',
      tickmode: 'array',
      tickvals: [-maxY, -maxY / 2, 0, maxY / 2, maxY],
      range: [-maxY, maxY],
    },
    legend: {
      xanchor: 'right',
      yanchor: 'bottom',
      y: 0.01,
      x: 0.995,
      bgcolor: 'rgba(0,0,0,0.01)',
      bordercolor: '#a2a0a0',
      borderwidth: 0.5,
    },
  });
};

export default buildLayout;
