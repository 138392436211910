class MihMrhPlotData {

  /**
   * @param {Array<float>} fields
   * @param {Array<float>} mihMoments
   * @param {Array<float>} mrhMoments
   * @param {string} fieldUnits
   * @param {string} momentUnits
   */
  constructor({
                fields,
                mihMoments,
                mrhMoments,
                fieldUnits,
                momentUnits,
              }) {
    this.fields = fields;
    this.mihMoments = mihMoments;
    this.mrhMoments = mrhMoments;
    this.fieldUnits = fieldUnits;
    this.momentUnits = momentUnits;
  }

  /**
   * @return {boolean}
   */
  isEmpty() {
    return this.fields.length === 0;
  }

  /**
   * @param {ProcessedHysteresisLoop} loop
   * @return {MihMrhPlotData}
   */
  static fromProcessedHysteresisLoop(loop) {
    return new MihMrhPlotData({
      fields: loop.fields,
      mihMoments: loop.induced_hysteretic_magnetization_moments,
      mrhMoments: loop.remanent_hysteretic_magnetization_moments,
      fieldUnits: loop.field_units,
      momentUnits: loop.moment_units,
    });
  }

  /**
   * @return {MihMrhPlotData}
   */
  static empty() {
    return new MihMrhPlotData({
      fields: [],
      mihMoments: [],
      mrhMoments: [],
      fieldUnits: '',
      momentUnits: '',
    });
  }

}

export default MihMrhPlotData;
