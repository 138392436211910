const ProcessingAction = {
  SWITCH_UNIT_SYSTEM: 'switch_unit_system',
  NORMALIZE: 'normalize',
  CORRECT_OFFSET: 'correct_offset',
  CORRECT_DRIFT: 'correct_drift',
  CORRECT_SLOPE: 'correct_slope',
  SMOOTH_HYSTERESIS_DATA: 'smooth_hysteresis_data',
  SMOOTH_IRM_DATA: 'smooth_irm_data',
  SMOOTH_BACKFIELD_DATA: 'smooth_backfield_data',
};

export default ProcessingAction;
