import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Workspace from './pages/workspace';
import MainPage from './pages/main-page';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' exact element={<MainPage />} />
      </Routes>
    </Router>
  );
};
export default App;
