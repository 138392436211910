import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ProcessedCurvesPlot from '../plot/processed-data/processed-curves-plot';
import MihMrhPlot from '../plot/mih-mrh/mih-mrh-plot';
import HysteresisNoisePlot from '../plot/hysteresis-noise/hysteresis-noise-plot';
import ProcessingMethodTitle from '../../components/titles/processing-method-title';
import MagneticProps from '../stats/magnetic-props';
import ProcessingStats from '../stats/processing-stats';
import { useFileManagerState } from '../../context/file-manager-provider';
import ParsedDataTable from '../parsed-data/table';

const SelectedSource = React.memo(({ curve, source }) =>
  <ProcessingMethodTitle primary={`${curve} Source`} secondary={source || 'unknown'} />,
);

const showSelectedSource = (useContext, selector, curve) => {
  return () => {
    const source = selector(useContext());

    return <SelectedSource curve={curve} source={source} />;
  };
};

const ProcessingResults = ({
                             plotSettings,
                           }) => {
  const HysteresisSource = showSelectedSource(useFileManagerState, (state) => state.hysteresisSource, 'Hysteresis');
  const IrmSource = showSelectedSource(useFileManagerState, (state) => state.irmSource, 'IRM');
  const BackfieldSource = showSelectedSource(useFileManagerState, (state) => state.backfieldSource, 'Backfield');

  return (
    <Box marginY='10px' width='100%' display='flex'>
      <Box sx={{ flexGrow: 1, margin: '10px', width: '75%' }}>
        <Grid container spacing={0.5} rowSpacing={0.5} justifyContent='center'>
          <Grid item xs={6}>
            <MihMrhPlot settings={plotSettings} />
          </Grid>
          <Grid item xs={6}>
            <HysteresisNoisePlot settings={plotSettings} />
          </Grid>
          <Grid item xs={2}>
            <HysteresisSource />
            <IrmSource />
            <BackfieldSource />
          </Grid>
          <Grid item xs={10}>
            <ProcessedCurvesPlot settings={plotSettings} />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, margin: '10px', width: '25%' }}>
        <MagneticProps />
        <ProcessingStats />
        <ParsedDataTable />
      </Box>
    </Box>
  );
};

export default ProcessingResults;
