export default class NamedUnprocessedMeasuredData {
  /**
   * @param {string} name
   * @param {UnprocessedMeasuredData} unprocessedData
   */
  constructor(name, unprocessedData) {
    this.name = name;
    this.unprocessed_data = unprocessedData;
  }
}
