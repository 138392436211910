/**
 * @param {string} mode
 * @param {string} name
 * @param {string} color
 * @param {Array<float>} xs
 * @param {Array<float>} ys
 * @param {string} visible
 * @return {{mode, line: {width: number, simplify: boolean, smoothing: number}, marker: {size: number}, showlegend: boolean, name, x, y, type: string}}
 */
const buildScatterPlotDataItem = ({
                                    mode,
                                    name,
                                    color,
                                    xs,
                                    ys,
                                    visible,
                                  }) => {
  return ({
    name: name,
    showlegend: true,
    visible: visible,
    x: xs,
    y: ys,
    type: 'scatter',
    mode: mode,
    line: {
      simplify: false,
      smoothing: 0,
      width: 0.9,
      color: color,
    },
    marker: {
      size: 3,
      color: color,
    },
  });
};

export default buildScatterPlotDataItem;
