const FileManagerContextAction = {
  SET_SOURCES: 'SET_SOURCES',
  SET_HYSTERESIS_SOURCE: 'SET_HYSTERESIS_SOURCE',
  UPDATE_HYSTERESIS_SOURCE: 'UPDATE_HYSTERESIS_SOURCE',
  SET_IRM_SOURCE: 'SET_IRM_SOURCE',
  UPDATE_IRM_SOURCE: 'UPDATE_IRM_SOURCE',
  SET_BACKFIELD_SOURCE: 'SET_BACKFIELD_SOURCE',
  UPDATE_BACKFIELD_SOURCE: 'UPDATE_BACKFIELD_SOURCE',
};

export default FileManagerContextAction;
