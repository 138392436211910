const InitialProcessingSpecValue = {
  common: {
    unit_system: '',
    normalization: {
      method: '',
      value: null,
    },
  },
  hysteresis: {
    offset_correction: false,
    drift_correction: {
      method: '',
    },
    slope_correction: {
      method: '',
    },
    noise_cancellation: {
      method: '',
    },
  },
  irm: {
    noise_cancellation: {
      method: '',
    },
  },
  backfield_demagnetization: {
    noise_cancellation: {
      method: '',
    },
  },
};

export default InitialProcessingSpecValue;
