import OutlinedInput from '@mui/material/OutlinedInput';

const ProcessingMethodOutlinedInput = ({ disabled, placeholder, endAdornment, inputRef, initialValue }) => {
  return (
    <OutlinedInput
      size='small'
      disabled={disabled}
      type='number'
      placeholder={placeholder}
      endAdornment={endAdornment}
      sx={{ width: '60%', fontSize: '10px' }}
      inputRef={inputRef}
      defaultValue={initialValue}
    />
  );
};

export default ProcessingMethodOutlinedInput;
