const buildInsetLayout = (xRange, yRange) => {
  return ({
    yaxis2: {
      domain: [0.65, 0.95],
      exponentformat: 'e',
      linecolor: 'black',
      mirror: true,
      anchor: 'x2',
      fixedrange: true,
      tickformat: '.0e',
      range: yRange,
      tickfont: {
        size: 6,
      },
    },
    xaxis2: {
      nticks: 8,
      domain: [0.05, 0.35],
      exponentformat: 'e',
      linecolor: 'black',
      mirror: true,
      anchor: 'y2',
      fixedrange: true,
      tickformat: '.0e',
      range: xRange,
      tickfont: {
        size: 6,
      },
    },
  });
};

export default buildInsetLayout;
