import React from 'react';
import { Button, Checkbox, Collapse, Fade, ListItemText, Menu, MenuItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const PlotMenu = ({ plotSettings, setPlotSettings }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuDisplayExpanded, setMenuDisplayExpanded] = React.useState(false);
  const open = Boolean(anchorEl);

  const handlePlotMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePlotMenuClose = () => {
    setMenuDisplayExpanded(false);
    setAnchorEl(null);
  };

  const handleShowMarkersChange = () => {
    const newPlotSettings = plotSettings.copy();
    newPlotSettings.showMarkers = !plotSettings.showMarkers;
    setPlotSettings(newPlotSettings);
  };

  const handleShowLinesChange = () => {
    const newPlotSettings = plotSettings.copy();
    newPlotSettings.showLines = !plotSettings.showLines;
    setPlotSettings(newPlotSettings);
  };

  const handleMenuDisplayClick = () => {
    setMenuDisplayExpanded(!menuDisplayExpanded);
  };

  return (
    <React.Fragment>
      <Button
        id='plot-menu'
        aria-controls={open ? 'fade-plot-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handlePlotMenuClick}
        sx={{ my: 2, color: 'white', display: 'block' }}
      >
        PLOT
      </Button>
      <Menu
        id='fade-plot-menu'
        MenuListProps={{
          'aria-labelledby': 'plot-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePlotMenuClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleMenuDisplayClick} dense>
          <ListItemText>Display</ListItemText>
          {menuDisplayExpanded ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={menuDisplayExpanded} timeout='auto' unmountOnExit>
          <label htmlFor='show-lines-checkbox'>
            <MenuItem dense>
              <ListItemText>Lines</ListItemText>
              <Checkbox id='show-lines-checkbox'
                        size='small'
                        checked={plotSettings.showLines}
                        onChange={handleShowLinesChange} />
            </MenuItem>
          </label>
          <label htmlFor='show-markers-checkbox'>
            <MenuItem dense>
              <ListItemText>Markers</ListItemText>
              <Checkbox id='show-markers-checkbox'
                        size='small'
                        checked={plotSettings.showMarkers}
                        onChange={handleShowMarkersChange} />
            </MenuItem>
          </label>
        </Collapse>
      </Menu>
    </React.Fragment>
  );

};

export default PlotMenu;
