import React from 'react';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import ProcessingMenu from '../processing/processing-menu';
import ProcessingResults from '../processing/processing-results';

const drawerWidth = 250;

/**
 * @param {PlotSettings} plotSettings
 * @return {JSX.Element}
 * @constructor
 */
const Workspace = ({
                        plotSettings,
                      }) => {
  return (
    <React.Fragment>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='permanent'
        anchor='left'
      >
        <ProcessingMenu />
      </Drawer>
      <Container component='main'>
        <Toolbar />
        <ProcessingResults plotSettings={plotSettings} />
      </Container>
    </React.Fragment>
  );
};

export default Workspace;
