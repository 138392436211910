import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';

const ProcessingMethodTitle = ({ primary, secondary }) => (
  <ListItem sx={{ padding: '0 5px 0 5px' }}>
    <ListItemText primaryTypographyProps={{ fontSize: '14px' }} primary={primary}
                  secondaryTypographyProps={{ fontSize: '10px' }} secondary={secondary} />
  </ListItem>
);

export default ProcessingMethodTitle;
