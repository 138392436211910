import React from 'react';
import ListItem from '@mui/material/ListItem';
import { MenuItem, Select } from '@mui/material';
import { useProcessingSpecDispatcher, useProcessingSpecState } from '../../../context/processing-spec-provider';
import { useUndoRedoDispatcher } from '../../../context/undo-redo-provider';
import ProcessingAction from '../../../context/processing-spec/action';

const supportedOffsetCorrectionMethods = {
  'false': 'No',
  'true': 'Yes',
};

const OffsetCorrectionControllerInner = React.memo(({ currentValue, onChange }) => {
  return (
    <ListItem sx={{ padding: '0 5px 0 5px' }}>
      <Select
        size='small'
        value={currentValue}
        onChange={onChange}
        sx={{ width: '60%', fontSize: '10px' }}
      >
        {
          Object
            .keys(supportedOffsetCorrectionMethods)
            .map((key) => <MenuItem key={key} value={key} dense>{supportedOffsetCorrectionMethods[key]}</MenuItem>)
        }
      </Select>
    </ListItem>
  );
});

const OffsetCorrectionController = () => {
  const state = useProcessingSpecState();
  const process = useProcessingSpecDispatcher();
  const undoRedo = useUndoRedoDispatcher();

  const onChange = React.useCallback((event) => {
    event.preventDefault();

    undoRedo(
      () => process({
        type: ProcessingAction.CORRECT_OFFSET,
        offset_correction: event.target.value === 'true',
      }),
      () => process({
        type: ProcessingAction.CORRECT_OFFSET,
        offset_correction: state.hysteresis.offset_correction,
      }),
    );
  }, [state.hysteresis.offset_correction]);

  return (
    <OffsetCorrectionControllerInner currentValue={state.hysteresis.offset_correction} onChange={onChange} />
  );
};

export default OffsetCorrectionController;
