import ApiError from '../common/api-error';
import { ProcessedHysteresisLoop } from './process-hysteresis-models';

const processHysteresisLoopV2 = (commonProcessingSpec, loopProcessingSpec, unprocessedLoop) => {
  const requestBody = {
    'common_processing_spec': commonProcessingSpec,
    'loop_processing_spec': loopProcessingSpec,
    'unprocessed_loop': unprocessedLoop,
  };

  return fetch(`/api/specimen/measurement/processing/hysteresis/v2`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((parsedError) => {
        throw new ApiError(response.status, parsedError.message);
      });
    }
    return response.json();
  }).then((parsedBody) => new ProcessedHysteresisLoop(parsedBody));
};

export default processHysteresisLoopV2;
