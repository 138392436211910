export class ProcessedHysteresisPlotData {
  /**
   * @param {Array<float>} topCurveFields
   * @param {Array<float>} topCurveMoments
   * @param {Array<float>} bottomCurveFields
   * @param {Array<float>} bottomCurveMoments
   */
  constructor({
                topCurveFields,
                topCurveMoments,
                bottomCurveFields,
                bottomCurveMoments,
              }) {

    this.topCurveFields = topCurveFields;
    this.topCurveMoments = topCurveMoments;
    this.bottomCurveFields = bottomCurveFields;
    this.bottomCurveMoments = bottomCurveMoments;
  }

  /**
   * @return {boolean}
   */
  isEmpty() {
    return this.topCurveFields.length === 0 && this.bottomCurveFields.length === 0;
  }

  /**
   * @return {ProcessedHysteresisPlotData}
   */
  static empty() {
    return new ProcessedHysteresisPlotData({
      topCurveFields: [],
      topCurveMoments: [],
      bottomCurveFields: [],
      bottomCurveMoments: [],
    });
  }

  /**
   * @param {ProcessedHysteresisLoop} loop
   * @return {ProcessedHysteresisPlotData}
   */
  static fromProcessedLoop(loop) {
    return new ProcessedHysteresisPlotData({
      topCurveFields: loop.loop.top_curve_fields,
      topCurveMoments: loop.loop.top_curve_moments,
      bottomCurveFields: loop.loop.bottom_curve_fields,
      bottomCurveMoments: loop.loop.bottom_curve_moments,
    });
  }
}

export class ProcessedIrmPlotData {
  /**
   * @param {Array<float>} fields
   * @param {Array<float>} moments
   */
  constructor({
                fields,
                moments,
              }) {

    this.fields = fields;
    this.moments = moments;
  }

  /**
   * @return {boolean}
   */
  isEmpty() {
    return this.fields.length === 0;
  }

  /**
   * @return {ProcessedIrmPlotData}
   */
  static empty() {
    return new ProcessedIrmPlotData({
      fields: [],
      moments: [],
    });
  }

  /**
   * @param {ProcessedIrm} curve
   * @return {ProcessedIrmPlotData}
   */
  static fromProcessedCurve(curve) {
    return new ProcessedIrmPlotData({
      fields: curve.fields,
      moments: curve.remanence_moments,
    });
  }
}

export class ProcessedBackfieldDemagnetizationPlotData {
  /**
   * @param {Array<float>} fields
   * @param {Array<float>} moments
   */
  constructor({
                fields,
                moments,
              }) {

    this.fields = fields;
    this.moments = moments;
  }

  /**
   * @return {boolean}
   */
  isEmpty() {
    return this.fields.length === 0;
  }

  /**
   * @return {ProcessedBackfieldDemagnetizationPlotData}
   */
  static empty() {
    return new ProcessedBackfieldDemagnetizationPlotData({
      fields: [],
      moments: [],
    });
  }

  /**
   * @param {ProcessedBackfieldDemagnetization} curve
   * @return {ProcessedBackfieldDemagnetizationPlotData}
   */
  static fromProcessedCurve(curve) {
    return new ProcessedBackfieldDemagnetizationPlotData({
      fields: curve.fields,
      moments: curve.remanence_moments,
    });
  }
}

export class SlopeTangentPlotData {
  constructor({
                fields,
                moments,
              }) {
    this.fields = fields;
    this.moments = moments;
  }

  /**
   * @return {boolean}
   */
  isEmpty() {
    return this.fields.length === 0;
  }

  /**
   * @return {SlopeTangentPlotData}
   */
  static empty() {
    return new SlopeTangentPlotData({
      fields: [],
      moments: [],
    });
  }

  /**
   * @param {ProcessedHysteresisLoop} loop
   * @return {SlopeTangentPlotData}
   */
  static fromProcessedLoop(loop) {
    return new SlopeTangentPlotData({
      fields: loop.slope_tangent_fields,
      moments: loop.slope_tangent_moments,
    });
  }
}

export class HcrLinearPlotData {
  constructor({
                fields,
                moments,
              }) {
    this.fields = fields;
    this.moments = moments;
  }

  /**
   * @return {boolean}
   */
  isEmpty() {
    return this.fields.length === 0;
  }

  /**
   * @return {HcrLinearPlotData}
   */
  static empty() {
    return new HcrLinearPlotData({
      fields: [],
      moments: [],
    });
  }

  /**
   * @param {ProcessedBackfieldDemagnetization} curve
   * @return {HcrLinearPlotData}
   */
  static fromProcessedCurve(curve) {
    return new HcrLinearPlotData({
      fields: curve.hcr_linear_fields,
      moments: curve.hcr_linear_moments,
    });
  }
}


class ProcessedCurvesPlotData {
  /**
   * @param {ProcessedHysteresisPlotData} hysteresis
   * @param {ProcessedIrmPlotData} irm
   * @param {ProcessedBackfieldDemagnetizationPlotData} backfieldDemagnetization
   * @param {SlopeTangentPlotData} slopeTangent
   * @param {HcrLinearPlotData} hcrLinear
   * @param {string} fieldUnits
   * @param {string} momentUnits
   */
  constructor({
                hysteresis,
                irm,
                backfieldDemagnetization,
                slopeTangent,
                hcrLinear,
                fieldUnits,
                momentUnits,
              }) {
    this.hysteresis = hysteresis;
    this.irm = irm;
    this.backfieldDemagnetization = backfieldDemagnetization;
    this.slopeTangent = slopeTangent;
    this.hcrLinear = hcrLinear;
    this.fieldUnits = fieldUnits;
    this.momentUnits = momentUnits;
  }

  /**
   * @return {ProcessedCurvesPlotData}
   */
  static empty() {
    return new ProcessedCurvesPlotData({
      hysteresis: ProcessedHysteresisPlotData.empty(),
      irm: ProcessedIrmPlotData.empty(),
      backfieldDemagnetization: ProcessedBackfieldDemagnetizationPlotData.empty(),
      slopeTangent: SlopeTangentPlotData.empty(),
      hcrLinear: HcrLinearPlotData.empty(),
      fieldUnits: '',
      momentUnits: '',
    });
  }
}

export default ProcessedCurvesPlotData;
