import React from 'react';
import UnprocessedDataStorage from '../storage/unprocessed-data-storage';
import CurveType from '../services/hysteresis-online/common/curve-type';
import processHysteresisLoopV2 from '../services/hysteresis-online/processing/process-hysteresis-v2';

/**
 * @type {{
 *   status: string,
 *   error: string|null,
 *   processedHysteresis: ProcessedHysteresisLoop|null
 * }}
 */
const initialState = {
  status: 'idle',
  error: null,
  processedHysteresis: null,
};

/**
 * @param {string|null} fileName
 * @return {{
 *  source: string,
 *  unprocessedData: UnprocessedMeasuredData
 * }|null}
 */
const fetchHysteresisFromStorage = (fileName) => {
  if (!fileName) {
    return null;
  }

  let data = UnprocessedDataStorage.get(fileName, CurveType.HYSTERESIS);
  if (!data) {
    data = UnprocessedDataStorage.get(fileName, CurveType.HYSTERESIS_IRM_DCD);
  }
  if (!data) {
    return null;
  }
  return {
    source: data.name,
    unprocessedData: data.unprocessed_data,
  };
};

/**
 *
 * @param {string|null} source
 * @param {CommonProcessingSpec} commonProcessingSpec
 * @param {HysteresisLoopProcessingSpec} specializedProcessingSpec
 * @return {{
 *   unprocessedHysteresis: UnprocessedMeasuredData|null,
 *   processedHysteresis: ProcessedHysteresisLoop|null
 * }}
 */
const useHysteresisProcessor = ({
                                  source,
                                  commonProcessingSpec,
                                  specializedProcessingSpec,
                                }) => {
  const cache = React.useRef({});
  const hysteresis = React.useMemo(() => fetchHysteresisFromStorage(source), [source]);
  const [processedData, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case 'RESET':
        return initialState;
      case 'PROCESSING':
        return { ...initialState, status: 'processing' };
      case 'PROCESSED':
        return { ...initialState, status: 'processed', processedHysteresis: action.payload };
      case 'PROCESSING_ERROR':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);

  React.useEffect(() => {
      let cancelRequest = false;
      if (!hysteresis) {
        dispatch({ type: 'RESET' });
        return;
      }

      const processData = async () => {
        dispatch({ type: 'PROCESSING' });
        if (cache.current[hysteresis.source]) {
          const data = cache.current[hysteresis.source];
          dispatch({ type: 'PROCESSED', payload: data });
        } else {
          try {
            const data = await processHysteresisLoopV2(
              commonProcessingSpec,
              specializedProcessingSpec,
              hysteresis.unprocessedData.getRawHysteresisData(),
            );
            // cache.current[hysteresis.source] = data; TODO
            if (cancelRequest) return;
            dispatch({ type: 'PROCESSED', payload: data });
          } catch (error) {
            if (cancelRequest) return;
            dispatch({ type: 'PROCESSING_ERROR', payload: error.message });
          }
        }
      };

      processData();

      return function cleanup() {
        cancelRequest = true;
      };
    },
    [hysteresis, commonProcessingSpec, specializedProcessingSpec],
  );

  return {
    unprocessedHysteresis: hysteresis?.unprocessedData,
    processedHysteresis: processedData.processedHysteresis,
  };
};

export default useHysteresisProcessor;
