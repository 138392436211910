import FileManagerProvider from './file-manager-provider';
import ProcessingSpecProvider from './processing-spec-provider';
import HysteresisProvider from './hysteresis-provider';
import IrmProvider from './irm-provider';
import BackfieldProvider from './backfield-provider';
import UndoRedoProvider from './undo-redo-provider';

const AppProvider = ({ children }) => {
  return (
    <FileManagerProvider>
      <ProcessingSpecProvider>
        <HysteresisProvider>
          <IrmProvider>
            <BackfieldProvider>
              <UndoRedoProvider>
                {children}
              </UndoRedoProvider>
            </BackfieldProvider>
          </IrmProvider>
        </HysteresisProvider>
      </ProcessingSpecProvider>
    </FileManagerProvider>
  );
};

export default AppProvider;
