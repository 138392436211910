export class HysteresisProcessingStats {
  /**
   * @param {string} loop_quality
   * @param {string} final_loop_quality
   * @param {string} field_offset
   * @param {string} moment_offset
   * @param {string} closure_error
   * @param {string} lack_of_fit_f_value
   * @param {string} lack_of_fit_p_value
   * @param {string} mrh_quality
   * @param {string} mih_quality
   * @param {string} linear_fit_f_70_value
   * @param {string} linear_fit_p_70_value
   * @param {string} linear_fit_f_80_value
   * @param {string} linear_fit_p_80_value
   * @param {string} linear_fit_f_90_value
   * @param {string} linear_fit_p_90_value
   */
  constructor({
                loop_quality,
                final_loop_quality,
                field_offset,
                moment_offset,
                closure_error,
                lack_of_fit_f_value,
                lack_of_fit_p_value,
                mrh_quality,
                mih_quality,
                linear_fit_f_70_value,
                linear_fit_p_70_value,
                linear_fit_f_80_value,
                linear_fit_p_80_value,
                linear_fit_f_90_value,
                linear_fit_p_90_value,
              }) {
    this.loop_quality = loop_quality;
    this.final_loop_quality = final_loop_quality;
    this.field_offset = field_offset;
    this.moment_offset = moment_offset;
    this.closure_error = closure_error;
    this.lack_of_fit_f_value = lack_of_fit_f_value;
    this.lack_of_fit_p_value = lack_of_fit_p_value;
    this.mrh_quality = mrh_quality;
    this.mih_quality = mih_quality;
    this.linear_fit_f_70_value = linear_fit_f_70_value;
    this.linear_fit_p_70_value = linear_fit_p_70_value;
    this.linear_fit_f_80_value = linear_fit_f_80_value;
    this.linear_fit_p_80_value = linear_fit_p_80_value;
    this.linear_fit_f_90_value = linear_fit_f_90_value;
    this.linear_fit_p_90_value = linear_fit_p_90_value;
  }
}

export class HysteresisMagneticProperties {
  /**
   * @param {float} coercivity_field
   * @param {float} saturation_remanence_moment
   * @param {float} saturation_field
   * @param {float} saturation_moment
   */
  constructor({
                coercivity_field,
                saturation_remanence_moment,
                saturation_field,
                saturation_moment,
              }) {
    this.coercivity_field = coercivity_field;
    this.saturation_remanence_moment = saturation_remanence_moment;
    this.saturation_field = saturation_field;
    this.saturation_moment = saturation_moment;
  }
}

export class ProcessedOriginalHysteresisLoop {
  /**
   * @param {Array<float>} top_curve_fields
   * @param {Array<float>} top_curve_moments
   * @param {Array<float>} bottom_curve_fields
   * @param {Array<float>} bottom_curve_moments
   */
  constructor({
                top_curve_fields,
                top_curve_moments,
                bottom_curve_fields,
                bottom_curve_moments,
              }) {
    this.top_curve_fields = top_curve_fields;
    this.top_curve_moments = top_curve_moments;
    this.bottom_curve_fields = bottom_curve_fields;
    this.bottom_curve_moments = bottom_curve_moments;
  }
}

export class ProcessedHysteresisLoop {

  /**
   * @param {HysteresisProcessingStats} stats
   * @param {HysteresisMagneticProperties} magnetic_props
   * @param {Array<float>} fields
   * @param {Array<float>} top_curve_moments
   * @param {Array<float>} bottom_curve_moments
   * @param {ProcessedOriginalHysteresisLoop} loop
   * @param {Array<float>} induced_hysteretic_magnetization_moments
   * @param {Array<float>} remanent_hysteretic_magnetization_moments
   * @param {Array<float>} noize
   * @param {Array<float>} slope_tangent_moments
   * @param {Array<float>} slope_tangent_fields
   * @param {string} field_units
   * @param {string} moment_units
   */
  constructor({
                stats,
                magnetic_props,
                fields,
                top_curve_moments,
                bottom_curve_moments,
                loop,
                induced_hysteretic_magnetization_moments,
                remanent_hysteretic_magnetization_moments,
                noize,
                slope_tangent_moments,
                slope_tangent_fields,
                field_units,
                moment_units,
              }) {
    this.stats = stats;
    this.magnetic_props = magnetic_props;
    this.fields = fields;
    this.top_curve_moments = top_curve_moments;
    this.bottom_curve_moments = bottom_curve_moments;
    this.loop = loop;
    this.induced_hysteretic_magnetization_moments = induced_hysteretic_magnetization_moments;
    this.remanent_hysteretic_magnetization_moments = remanent_hysteretic_magnetization_moments;
    this.noize = noize;
    this.slope_tangent_moments = slope_tangent_moments;
    this.slope_tangent_fields = slope_tangent_fields;
    this.field_units = field_units;
    this.moment_units = moment_units;
  }

  /**
   * @return {Array<Point>}
   */
  originalLoopPoints() {
    const data = [];
    for (let i = 0; i < this.loop.top_curve_fields.length; i++) {
      data.push({
        field: this.loop.top_curve_fields[i],
        moment: this.loop.top_curve_moments[i],
      });
    }
    for (let i = this.loop.bottom_curve_fields.length - 1; i >= 0; i--) {
      data.push({
        field: this.loop.bottom_curve_fields[i],
        moment: this.loop.bottom_curve_moments[i],
      });
    }
    return data;
  }
}