import React from 'react';
import {
  Button,
  Collapse,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Radio,
} from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UnprocessedDataStorage from '../../storage/unprocessed-data-storage';
import DeleteIcon from '@mui/icons-material/Delete';
import uploadFile from '../../services/hysteresis-online/unprocessed-data/upload-file';
import CurveType from '../../services/hysteresis-online/common/curve-type';
import { useFileManagerDispatcher, useFileManagerState } from '../../context/file-manager-provider';
import { useUndoRedoDispatcher } from '../../context/undo-redo-provider';
import { useProcessingSpecDispatcher, useProcessingSpecState } from '../../context/processing-spec-provider';
import ProcessingAction from '../../context/processing-spec/action';

const FileDataUploadButtonNew = ({ measured_curve, text, finalize }) => {
  const undoRedo = useUndoRedoDispatcher();
  const { hysteresisSource, irmSource, backfieldSource } = useFileManagerState();
  const { setSources, setHysteresisSource, setIrmSource, setBackfieldSource } = useFileManagerDispatcher();
  const { common: commonProcessingSpec } = useProcessingSpecState();
  const dispatchProcessingSpec = useProcessingSpecDispatcher();
  const buttonId = `${measured_curve}-data-upload-button`;

  const handleFileUpload = ({ target }) => {
    if (target.files.length !== 1) {
      return;
    }
    const file = target.files[0];
    const currentUnitSystem = commonProcessingSpec.unit_system;

    uploadFile(measured_curve, file)
      .then((unprocessedMeasuredData) => {
        const savedFileName = UnprocessedDataStorage.saveNew(file.name, unprocessedMeasuredData);

        switch (measured_curve) {
          case CurveType.HYSTERESIS: {
            undoRedo(
              () => {
                setHysteresisSource(savedFileName);
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: unprocessedMeasuredData.unit_system,
                });
              },
              () => {
                setSources(hysteresisSource, irmSource, backfieldSource);
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: currentUnitSystem,
                });
              },
            );
            break;
          }
          case CurveType.IRM: {
            undoRedo(
              () => {
                setIrmSource(savedFileName);
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: unprocessedMeasuredData.unit_system,
                });
              },
              () => {
                setSources(hysteresisSource, irmSource, backfieldSource);
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: currentUnitSystem,
                });
              },
            );
            break;
          }
          case CurveType.BACKFIELD_DEMAGNETIZATION: {
            undoRedo(
              () => {
                setBackfieldSource(savedFileName);
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: unprocessedMeasuredData.unit_system,
                });
              },
              () => {
                setSources(hysteresisSource, irmSource, backfieldSource);
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: currentUnitSystem,
                });
              },
            );
            break;
          }
          case CurveType.IRM_AND_DCD: {
            undoRedo(
              () => {
                setSources(null, savedFileName, savedFileName);
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: unprocessedMeasuredData.unit_system,
                });
              },
              () => {
                setSources(hysteresisSource, irmSource, backfieldSource);
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: currentUnitSystem,
                });
              },
            );
            break;
          }
          case CurveType.HYSTERESIS_IRM_DCD: {
            undoRedo(
              () => {
                setSources(savedFileName, savedFileName, savedFileName);
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: unprocessedMeasuredData.unit_system,
                });
              },
              () => {
                setSources(hysteresisSource, irmSource, backfieldSource);
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: currentUnitSystem,
                });
              },
            );
            break;
          }
          default: {
            throw Error(`Unsupported measurement: ${measured_curve}`);
          }
        }
      })
      .catch((err) => alert(err.toString()))
      .finally(() => finalize());
  };

  return (
    <React.Fragment>
      <input
        style={{ display: 'none' }}
        id={buttonId}
        onChange={handleFileUpload}
        type='file'
      />
      <label htmlFor={buttonId}>
        <MenuItem dense>
          <ListItemText>{text}</ListItemText>
        </MenuItem>
      </label>
    </React.Fragment>
  );
};

const PreviouslyUploadedFile = ({ measuredCurve, fileName, selectItem, removeItem, source }) => {
  return (
    <ListItem disablePadding>
      <ListItemText
        primaryTypographyProps={{ fontSize: '12px' }}
        primary={fileName}
        sx={{ marginLeft: '2px', cursor: 'pointer' }}
      />
      <Radio
        checked={source === fileName}
        onClick={selectItem}
        value={fileName}
        name={measuredCurve}
        size='small'
      />
      <IconButton
        size='small'
        onClick={removeItem}>
        <DeleteIcon fontSize='inherit' />
      </IconButton>
    </ListItem>
  );
};

const UploadedHysteresisLoops = () => {
  const undoRedo = useUndoRedoDispatcher();
  const { hysteresisSource, irmSource, backfieldSource } = useFileManagerState();
  const { updateHysteresisSource } = useFileManagerDispatcher();
  const { common: commonProcessingSpec } = useProcessingSpecState();
  const dispatchProcessingSpec = useProcessingSpecDispatcher();
  const [uploadedLoops, setUploadedLoops] = React.useState(UnprocessedDataStorage.getAll(CurveType.HYSTERESIS));

  const currentUnitSystem = commonProcessingSpec.unit_system;
  const switchUnitSystem = irmSource === null && backfieldSource === null;

  return uploadedLoops.map((loop) =>
    <PreviouslyUploadedFile
      key={`subsection-hysteresis-${loop.name}`}
      measuredCurve={CurveType.HYSTERESIS}
      fileName={loop.name}
      selectItem={(event) => {
        const mbTargetUnitSystem = loop.unprocessed_data.unit_system || 'si';
        let oldSource = hysteresisSource;
        let newSource = event.target.value;
        if (newSource === oldSource) {
          newSource = null;
        }

        undoRedo(
          () => {
            updateHysteresisSource(newSource);
            if (switchUnitSystem) {
              dispatchProcessingSpec({
                type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                unit_system: mbTargetUnitSystem,
              });
            }
          },
          () => {
            updateHysteresisSource(oldSource);
            if (switchUnitSystem) {
              dispatchProcessingSpec({
                type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                unit_system: currentUnitSystem,
              });
            }
          });
      }}
      removeItem={() => {
        undoRedo(
          () => {
            UnprocessedDataStorage.remove(loop.name, CurveType.HYSTERESIS);
            setUploadedLoops(uploadedLoops.filter((uLoop) => uLoop.name !== loop.name));
            if (hysteresisSource === loop.name) {
              updateHysteresisSource(null);
              if (switchUnitSystem) {
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: '',
                });
              }
            }
          },
          () => {
            UnprocessedDataStorage.saveNew(loop.name, loop.unprocessed_data);
            setUploadedLoops(uploadedLoops);
            if (hysteresisSource === loop.name) {
              updateHysteresisSource(hysteresisSource);
              if (switchUnitSystem) {
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: currentUnitSystem,
                });
              }
            }
          },
        );
      }}
      source={hysteresisSource}
    />,
  );
};

const UploadedIrmCurves = () => {
  const undoRedo = useUndoRedoDispatcher();
  const { hysteresisSource, irmSource, backfieldSource } = useFileManagerState();
  const { updateIrmSource } = useFileManagerDispatcher();
  const { common: commonProcessingSpec } = useProcessingSpecState();
  const dispatchProcessingSpec = useProcessingSpecDispatcher();
  const [uploadedIrms, setUploadedIrms] = React.useState(UnprocessedDataStorage.getAll(CurveType.IRM));

  const currentUnitSystem = commonProcessingSpec.unit_system;
  const switchUnitSystem = hysteresisSource === null && backfieldSource === null;

  return uploadedIrms.map((curve) =>
    <PreviouslyUploadedFile
      key={`subsection-irm-${curve.name}`}
      measuredCurve={CurveType.IRM}
      fileName={curve.name}
      selectItem={(event) => {
        const mbTargetUnitSystem = curve.unprocessed_data.unit_system || 'si';
        let oldSource = irmSource;
        let newSource = event.target.value;
        if (newSource === oldSource) {
          newSource = null;
        }
        undoRedo(
          () => {
            updateIrmSource(newSource);
            if (switchUnitSystem) {
              dispatchProcessingSpec({
                type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                unit_system: mbTargetUnitSystem,
              });
            }
          },
          () => {
            updateIrmSource(oldSource);
            if (switchUnitSystem) {
              dispatchProcessingSpec({
                type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                unit_system: currentUnitSystem,
              });
            }
          },
        );
      }}
      removeItem={() => {
        undoRedo(
          () => {
            UnprocessedDataStorage.remove(curve.name, CurveType.IRM);
            setUploadedIrms(uploadedIrms.filter((uCurve) => uCurve.name !== curve.name));
            if (irmSource === curve.name) {
              updateIrmSource(null);
              if (switchUnitSystem) {
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: '',
                });
              }
            }
          },
          () => {
            UnprocessedDataStorage.saveNew(curve.name, curve.unprocessed_data);
            setUploadedIrms(uploadedIrms);
            if (irmSource === curve.name) {
              updateIrmSource(irmSource);
              if (switchUnitSystem) {
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: currentUnitSystem,
                });
              }
            }
          },
        );
      }}
      source={irmSource}
    />,
  );
};

const UploadedBackfieldCurves = () => {
  const undoRedo = useUndoRedoDispatcher();
  const { hysteresisSource, irmSource, backfieldSource } = useFileManagerState();
  const { updateBackfieldSource } = useFileManagerDispatcher();
  const { common: commonProcessingSpec } = useProcessingSpecState();
  const dispatchProcessingSpec = useProcessingSpecDispatcher();
  const [uploadedBackfields, setUploadedBackfields] = React.useState(UnprocessedDataStorage.getAll(CurveType.BACKFIELD_DEMAGNETIZATION));

  const currentUnitSystem = commonProcessingSpec.unit_system;
  const switchUnitSystem = hysteresisSource === null && irmSource === null;

  return uploadedBackfields.map((curve) =>
    <PreviouslyUploadedFile
      key={`subsection-backfield-${curve.name}`}
      measuredCurve={CurveType.BACKFIELD_DEMAGNETIZATION}
      fileName={curve.name}
      selectItem={(event) => {
        const mbTargetUnitSystem = curve.unprocessed_data.unit_system || 'si';
        let oldSource = backfieldSource;
        let newSource = event.target.value;
        if (newSource === oldSource) {
          newSource = null;
        }
        undoRedo(
          () => {
            updateBackfieldSource(newSource);
            if (switchUnitSystem) {
              dispatchProcessingSpec({
                type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                unit_system: mbTargetUnitSystem,
              });
            }
          },
          () => {
            updateBackfieldSource(oldSource);
            if (switchUnitSystem) {
              dispatchProcessingSpec({
                type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                unit_system: currentUnitSystem,
              });
            }
          },
        );
      }}
      removeItem={() => {
        undoRedo(
          () => {
            UnprocessedDataStorage.remove(curve.name, CurveType.BACKFIELD_DEMAGNETIZATION);
            setUploadedBackfields(uploadedBackfields.filter((uCurve) => uCurve.name !== curve.name));
            if (backfieldSource === curve.name) {
              updateBackfieldSource(null);
              if (switchUnitSystem) {
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: '',
                });
              }
            }
          },
          () => {
            UnprocessedDataStorage.saveNew(curve.name, curve.unprocessed_data);
            setUploadedBackfields(uploadedBackfields);
            if (backfieldSource === curve.name) {
              updateBackfieldSource(backfieldSource);
              if (switchUnitSystem) {
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: currentUnitSystem,
                });
              }
            }
          },
        );
      }}
      source={backfieldSource}
    />,
  );
};

const UploadedIrmAndBackfieldCurves = () => {
  const undoRedo = useUndoRedoDispatcher();
  const { hysteresisSource, irmSource, backfieldSource } = useFileManagerState();
  const { updateIrmSource, updateBackfieldSource } = useFileManagerDispatcher();
  const { common: commonProcessingSpec } = useProcessingSpecState();
  const dispatchProcessingSpec = useProcessingSpecDispatcher();
  const [uploadedIrmAndBackfields, setUploadedIrmAndBackfields] = React.useState(UnprocessedDataStorage.getAll(CurveType.IRM_AND_DCD));

  const currentUnitSystem = commonProcessingSpec.unit_system;
  const switchUnitSystem = hysteresisSource === null;

  return uploadedIrmAndBackfields.map((curve) =>
    <PreviouslyUploadedFile
      key={`subsection-irm-and-backfield-${curve.name}`}
      measuredCurve={CurveType.IRM_AND_DCD}
      fileName={curve.name}
      selectItem={(event) => {
        const mbTargetUnitSystem = curve.unprocessed_data.unit_system || 'si';
        let oldIrmSource = irmSource;
        let oldBackfieldSource = backfieldSource;
        let newSource = event.target.value;
        if (newSource === oldBackfieldSource && newSource === oldIrmSource) {
          newSource = null;
        }
        undoRedo(
          () => {
            updateIrmSource(newSource);
            updateBackfieldSource(newSource);
            if (switchUnitSystem) {
              dispatchProcessingSpec({
                type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                unit_system: mbTargetUnitSystem,
              });
            }
          },
          () => {
            updateIrmSource(oldIrmSource);
            updateBackfieldSource(oldBackfieldSource);
            if (switchUnitSystem) {
              dispatchProcessingSpec({
                type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                unit_system: currentUnitSystem,
              });
            }
          },
        );
      }}
      removeItem={() => {
        undoRedo(
          () => {
            UnprocessedDataStorage.remove(curve.name, CurveType.IRM_AND_DCD);
            setUploadedIrmAndBackfields(uploadedIrmAndBackfields.filter((uCurve) => uCurve.name !== curve.name));
            if (irmSource === curve.name && backfieldSource === curve.name) {
              updateIrmSource(null);
              updateBackfieldSource(null);
              if (switchUnitSystem) {
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: '',
                });
              }
            }
          },
          () => {
            UnprocessedDataStorage.saveNew(curve.name, curve.unprocessed_data);
            setUploadedIrmAndBackfields(uploadedIrmAndBackfields);
            if (irmSource === curve.name && backfieldSource === curve.name) {
              updateIrmSource(irmSource);
              updateBackfieldSource(backfieldSource);
              if (switchUnitSystem) {
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: currentUnitSystem,
                });
              }
            }
          },
        );
      }}
      source={irmSource === backfieldSource ? irmSource : null}
    />,
  );
};

const UploadedHysteresisIrmAndBackfieldCurves = () => {
  const undoRedo = useUndoRedoDispatcher();
  const { hysteresisSource, irmSource, backfieldSource } = useFileManagerState();
  const { updateHysteresisSource, updateIrmSource, updateBackfieldSource } = useFileManagerDispatcher();
  const { common: commonProcessingSpec } = useProcessingSpecState();
  const dispatchProcessingSpec = useProcessingSpecDispatcher();
  const [uploadedHysteresisIrmAndBackfields, setUploadedHysteresisIrmAndBackfields] = React.useState(UnprocessedDataStorage.getAll(CurveType.HYSTERESIS_IRM_DCD));

  const currentUnitSystem = commonProcessingSpec.unit_system;
  const switchUnitSystem = false;

  return uploadedHysteresisIrmAndBackfields.map((curve) =>
    <PreviouslyUploadedFile
      key={`subsection-hysteresis-irm-and-backfield-${curve.name}`}
      measuredCurve={CurveType.HYSTERESIS_IRM_DCD}
      fileName={curve.name}
      selectItem={(event) => {
        const mbTargetUnitSystem = curve.unprocessed_data.unit_system || 'si';
        let oldHysteresisSource = hysteresisSource;
        let oldIrmSource = irmSource;
        let oldBackfieldSource = backfieldSource;
        let newSource = event.target.value;
        if (newSource === oldHysteresisSource && newSource === oldBackfieldSource && newSource === oldIrmSource) {
          newSource = null;
        }
        undoRedo(
          () => {
            updateHysteresisSource(newSource);
            updateIrmSource(newSource);
            updateBackfieldSource(newSource);
            if (switchUnitSystem) {
              dispatchProcessingSpec({
                type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                unit_system: mbTargetUnitSystem,
              });
            }
          },
          () => {
            updateHysteresisSource(oldHysteresisSource);
            updateIrmSource(oldIrmSource);
            updateBackfieldSource(oldBackfieldSource);
            if (switchUnitSystem) {
              dispatchProcessingSpec({
                type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                unit_system: currentUnitSystem,
              });
            }
          },
        );
      }}
      removeItem={() => {
        undoRedo(
          () => {
            UnprocessedDataStorage.remove(curve.name, CurveType.HYSTERESIS_IRM_DCD);
            setUploadedHysteresisIrmAndBackfields(uploadedHysteresisIrmAndBackfields.filter((uCurve) => uCurve.name !== curve.name));
            if (hysteresisSource === curve.name && irmSource === curve.name && backfieldSource === curve.name) {
              updateHysteresisSource(null);
              updateIrmSource(null);
              updateBackfieldSource(null);
              if (switchUnitSystem) {
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: '',
                });
              }
            }
          },
          () => {
            UnprocessedDataStorage.saveNew(curve.name, curve.unprocessed_data);
            setUploadedHysteresisIrmAndBackfields(uploadedHysteresisIrmAndBackfields);
            if (hysteresisSource === curve.name && irmSource === curve.name && backfieldSource === curve.name) {
              updateHysteresisSource(hysteresisSource);
              updateIrmSource(irmSource);
              updateBackfieldSource(backfieldSource);
              if (switchUnitSystem) {
                dispatchProcessingSpec({
                  type: ProcessingAction.SWITCH_UNIT_SYSTEM,
                  unit_system: currentUnitSystem,
                });
              }
            }
          },
        );
      }}
      source={hysteresisSource === irmSource && irmSource === backfieldSource ? hysteresisSource : null}
    />,
  );
};

const FileMenu = () => {
  const [menuFileUploadExpanded, setMenuFileUploadExpanded] = React.useState(false);
  const [menuFileOpenExpanded, setMenuFileOpenExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuFileUploadExpanded(false);
    setMenuFileOpenExpanded(false);
  };

  const handleMenuFileUploadClick = () => {
    setMenuFileUploadExpanded(!menuFileUploadExpanded);
  };

  const handleMenuFileOpenClick = () => {
    setMenuFileOpenExpanded(!menuFileOpenExpanded);
  };

  return (
    <React.Fragment>
      <Button
        id='file-menu'
        aria-controls={open ? 'fade-file-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ my: 2, color: 'white', display: 'block' }}
      >
        FILE
      </Button>
      <Menu
        id='fade-file-menu'
        MenuListProps={{
          'aria-labelledby': 'file-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleMenuFileUploadClick} dense>
          <ListItemIcon>
            <UploadFileIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Upload...</ListItemText>
          {menuFileUploadExpanded ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={menuFileUploadExpanded} timeout='auto' unmountOnExit>
          <FileDataUploadButtonNew
            measured_curve={CurveType.HYSTERESIS}
            text={'Hysteresis'}
            finalize={handleClose}
          />
          <FileDataUploadButtonNew
            measured_curve={CurveType.IRM}
            text={'IRM'}
            finalize={handleClose}
          />
          <FileDataUploadButtonNew
            measured_curve={CurveType.BACKFIELD_DEMAGNETIZATION}
            text={'Backfield'}
            finalize={handleClose}
          />
          <FileDataUploadButtonNew
            measured_curve={CurveType.IRM_AND_DCD}
            text={'IRM + Backfield'}
            finalize={handleClose}
          />
          <FileDataUploadButtonNew
            measured_curve={CurveType.HYSTERESIS_IRM_DCD}
            text={'Hysteresis + IRM + Backfield (JMeter)'}
            finalize={handleClose}
          />
        </Collapse>
        <MenuItem onClick={handleMenuFileOpenClick} dense>
          <ListItemIcon>
            <FileOpenIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Open...</ListItemText>
          {menuFileOpenExpanded ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={menuFileOpenExpanded} timeout='auto' unmountOnExit>
          <List
            sx={{
              width: '100%',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 200,
              '& ul': { padding: 0 },
            }}
            subheader={<li key='section-empty' />}
          >
            <li key='section-uploaded-measurements'>
              <ul>
                <ListSubheader>Hysteresis</ListSubheader>
                <UploadedHysteresisLoops />
                <ListSubheader>IRM</ListSubheader>
                <UploadedIrmCurves />
                <ListSubheader>Backfield</ListSubheader>
                <UploadedBackfieldCurves />
                <ListSubheader>IRM + Backfield</ListSubheader>
                <UploadedIrmAndBackfieldCurves />
                <ListSubheader>Hysteresis + IRM + Backfield (JMeter)</ListSubheader>
                <UploadedHysteresisIrmAndBackfieldCurves />
              </ul>
            </li>
          </List>
        </Collapse>
      </Menu>
    </React.Fragment>
  );
};

export default FileMenu;
