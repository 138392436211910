export default class UnprocessedMeasuredData {
  /**
   * @param {string} curve
   * @param {SpecimenInfo} specimen_info
   * @param {string} unit_system
   * @param {HysteresisLoop|RemanenceCurve|undefined} raw_data
   * @param {HysteresisLoop|undefined} raw_hysteresis_data
   * @param {RemanenceCurve|undefined} raw_irm_data
   * @param {RemanenceCurve|undefined} raw_backfield_data
   */
  constructor({
                curve,
                specimen_info,
                unit_system,
                raw_data,
                raw_hysteresis_data,
                raw_irm_data,
                raw_backfield_data,
              }) {
    this.curve = curve;
    this.specimen_info = specimen_info;
    this.unit_system = unit_system;
    this.raw_data = raw_data;
    this.raw_hysteresis_data = raw_hysteresis_data;
    this.raw_irm_data = raw_irm_data;
    this.raw_backfield_data = raw_backfield_data;
  }

  /**
   * @return {HysteresisLoop|undefined}
   */
  getRawHysteresisData() {
    if (this.raw_hysteresis_data) {
      return this.raw_hysteresis_data;
    }

    return this.raw_data;
  }

  /**
   * @return {RemanenceCurve|undefined}
   */
  getRawIrmData() {
    if (this.raw_irm_data) {
      return this.raw_irm_data;
    }

    return this.raw_data;
  }

  /**
   * @return {RemanenceCurve|undefined}
   */
  getRawBackfieldData() {
    if (this.raw_backfield_data) {
      return this.raw_backfield_data;
    }

    return this.raw_data;
  }
}
