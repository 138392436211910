import ApiError from '../common/api-error';
import { ProcessedBackfieldDemagnetization } from './process-backfield-demagnetization-models';

const processBackfieldDemagnetizationV2 = (commonProcessingSpec, dcdProcessingSpec, unprocessedCurve) => {
  const requestBody = {
    'common_processing_spec': commonProcessingSpec,
    'curve_processing_spec': dcdProcessingSpec,
    'unprocessed_curve': unprocessedCurve,
  };

  return fetch(`/api/specimen/measurement/processing/backfield-demagnetization/v2`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((parsedError) => {
        throw new ApiError(response.status, parsedError.message);
      });
    }
    return response.json();
  }).then((parsedBody) => new ProcessedBackfieldDemagnetization(parsedBody));
};

export default processBackfieldDemagnetizationV2;
