import ProcessingAction from './action';

const processingSpecContextReducer = (currentState, action) => {
  switch (action.type) {
    case ProcessingAction.SWITCH_UNIT_SYSTEM: {
      if (currentState.common.unit_system === action.unit_system) {
        return currentState;
      }
      return {
        ...currentState,
        common: {
          ...currentState.common,
          unit_system: action.unit_system,
        },
      };
    }
    case ProcessingAction.NORMALIZE: {
      if (
        currentState.common.normalization.method === action.method &&
        currentState.common.normalization.value === action.value
      ) {
        return currentState;
      }
      return {
        ...currentState,
        common: {
          ...currentState.common,
          normalization: {
            method: action.method,
            value: action.value,
          },
        },
      };
    }
    case ProcessingAction.CORRECT_OFFSET: {
      if (currentState.hysteresis.offset_correction === action.offset_correction) {
        return currentState;
      }
      return {
        ...currentState,
        hysteresis: {
          ...currentState.hysteresis,
          offset_correction: action.offset_correction,
        },
      };
    }
    case ProcessingAction.CORRECT_DRIFT: {
      if (currentState.hysteresis.drift_correction.method === action.method) {
        return currentState;
      }
      return {
        ...currentState,
        hysteresis: {
          ...currentState.hysteresis,
          drift_correction: {
            method: action.method,
          },
        },
      };
    }
    case ProcessingAction.CORRECT_SLOPE: {
      if (
        currentState.hysteresis.slope_correction.method === action.method &&
        currentState.hysteresis.slope_correction.saturation_field === action.saturation_field
      ) {
        return currentState;
      }
      return {
        ...currentState,
        hysteresis: {
          ...currentState.hysteresis,
          slope_correction: {
            method: action.method,
            saturation_field: action.saturation_field,
          },
        },
      };
    }
    case ProcessingAction.SMOOTH_HYSTERESIS_DATA: {
      if (
        currentState.hysteresis.noise_cancellation.method === action.method &&
        currentState.hysteresis.noise_cancellation.intensity === action.intensity
      ) {
        return currentState;
      }
      return {
        ...currentState,
        hysteresis: {
          ...currentState.hysteresis,
          noise_cancellation: {
            method: action.method,
            intensity: action.intensity,
          },
        },
      };
    }
    case ProcessingAction.SMOOTH_IRM_DATA: {
      if (
        currentState.irm.noise_cancellation.method === action.method &&
        currentState.irm.noise_cancellation.intensity === action.intensity
      ) {
        return currentState;
      }
      return {
        ...currentState,
        irm: {
          ...currentState.irm,
          noise_cancellation: {
            method: action.method,
            intensity: action.intensity,
          },
        },
      };
    }
    case ProcessingAction.SMOOTH_BACKFIELD_DATA: {
      if (
        currentState.backfield_demagnetization.noise_cancellation.method === action.method &&
        currentState.backfield_demagnetization.noise_cancellation.intensity === action.intensity
      ) {
        return currentState;
      }
      return {
        ...currentState,
        backfield_demagnetization: {
          ...currentState.backfield_demagnetization,
          noise_cancellation: {
            method: action.method,
            intensity: action.intensity,
          },
        },
      };
    }
    default:
      throw Error(`Unknown action: ${action.type}`);
  }
};

export default processingSpecContextReducer;
