export class BackfieldDemagnetizationMagneticProperties {
  /**
   * @param {string} coercivity_field
   */
  constructor({
                coercivity_field,
              }) {
    this.coercivity_field = coercivity_field;
  }
}


export class ProcessedBackfieldDemagnetization {
  /**
   * @param {BackfieldDemagnetizationMagneticProperties} magnetic_props
   * @param {Array<float>} fields
   * @param {Array<float>} remanence_moments
   * @param {Array<float>} hcr_linear_fields
   * @param {Array<float>} hcr_linear_moments
   * @param {string} field_units
   * @param {string} moment_units
   */
  constructor({
                magnetic_props,
                fields,
                remanence_moments,
                hcr_linear_fields,
                hcr_linear_moments,
                field_units,
                moment_units,
              }) {
    this.magnetic_props = magnetic_props;
    this.fields = fields;
    this.remanence_moments = remanence_moments;
    this.hcr_linear_fields = hcr_linear_fields;
    this.hcr_linear_moments = hcr_linear_moments;
    this.field_units = field_units;
    this.moment_units = moment_units;
  }

    /**
   * @return {Array<Point>}
   */
  points() {
    const data = [];
    for (let i = 0; i < this.fields.length; i++) {
      data.push({
        field: this.fields[i],
        moment: this.remanence_moments[i],
      });
    }
    return data;
  }
}
