import NamedUnprocessedMeasuredData from './model/named-unprocessed-measured-data';
import UnprocessedMeasuredData
  from '../services/hysteresis-online/unprocessed-data/unprocessed-measured-data-models';

class UnprocessedDataStorage {
  /**
   * @param {string} fileName
   * @param {UnprocessedMeasuredData} unprocessedMeasuredData
   * @return {string}
   */
  static saveNew(fileName, unprocessedMeasuredData) {
    let existingData = JSON.parse(localStorage.getItem(unprocessedMeasuredData.curve)) || [];

    const duplicatesCount = existingData.filter((item) => item.name.startsWith(fileName)).length;
    if (duplicatesCount > 0) {
      fileName = `${fileName} - ${duplicatesCount + 1}`;
    }
    existingData.push(new NamedUnprocessedMeasuredData(fileName, unprocessedMeasuredData));

    localStorage.setItem(unprocessedMeasuredData.curve, JSON.stringify(existingData));
    return fileName;
  };

  /**
   * @param {string} measuredCurve
   * @return {Array<NamedUnprocessedMeasuredData>}
   */
  static getAll(measuredCurve) {
    const namedData = JSON.parse(localStorage.getItem(measuredCurve)) || [];

    return namedData.map((item) =>
      new NamedUnprocessedMeasuredData(item.name, new UnprocessedMeasuredData(item.unprocessed_data)),
    );
  };

  /**
   * @param {string} fileName
   * @param {string} measuredCurve
   * @return {NamedUnprocessedMeasuredData|null}
   */
  static get(fileName, measuredCurve) {
    const existingData = JSON.parse(localStorage.getItem(measuredCurve)) || [];

    const targetData = existingData
      .filter((item) => item.name === fileName)
      .map((item) => new NamedUnprocessedMeasuredData(item.name, new UnprocessedMeasuredData(item.unprocessed_data)));
    if (targetData.length === 0) {
      return null;
    }
    return targetData[0];
  };

  /**
   * @param {string} fileName
   * @param {string} measuredCurve
   */
  static remove(fileName, measuredCurve) {
    const existingData = JSON.parse(localStorage.getItem(measuredCurve));
    if (existingData === null) {
      return;
    }

    localStorage.setItem(
      measuredCurve,
      JSON.stringify(existingData.filter((item) => item.name !== fileName)),
    );
  };
}

export default UnprocessedDataStorage;
